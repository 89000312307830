<div *ngIf="invoices && invoices.length">
  <ppf-card fxLayout="column" class="bar-chart-card pl-16 pr-16 pb-16">
    <h2 class="headline pt-24 pb-16 pl-8">{{ labels.firstTitle | flxLocalize }}</h2>
    <omc-invoices-bar-chart [data]="barChartData" [labels]="barChartLabels"></omc-invoices-bar-chart>
  </ppf-card>

  <ppf-card fxLayout="column" class="history-card">
    <h2 class="headline">{{ labels.secondTitle | flxLocalize }}</h2>
    <div fxLayout="row" class="table-actions" fxLayoutAlign="space-between center">
      <div>
        <ppf-select
          *ngIf="filterItems.length > 1 && !hasAggregatedInvoices"
          [options]="filterItems"
          [placeholder]="labels.filterPlaceHolder"
          [label]="labels.filterPlaceHolder"
          (selectChange)="handleFilterChange($event)"
          class="filter-select"
        >
        </ppf-select>
      </div>
      <div fxLayout="row" class="link-button export-csv-section">
        <ppf-icon name="download"></ppf-icon>
        <a (click)="handleCsvExport()" class="semibold">{{ labels.exportLabel | flxLocalize }}</a>
      </div>
    </div>
    <omc-table
      [data]="invoices"
      [columns]="columns"
      [notSortableColumnsIds]="['actions']"
      [templates]="{ actions: actionsColumnT, value: valueColumnT, status: statusColumnT }"
      (sortClicked)="sort($event)"
    >
      <ng-template #actionsColumnT let-data="rowValue" let-value="cellValue">
        <omc-dropdown-menu
          menuIcon="menu"
          xPosition="before"
          [yPosition]="invoices.indexOf(data) >= invoices.length - 2 ? 'above' : 'below'"
        >
          <div *ngFor="let item of getRightMenuActions(data)" (click)="item.action(item)" class="ppf-menu-item">
            {{ item.label | flxLocalize }}
          </div>
        </omc-dropdown-menu>
      </ng-template>

      <ng-template #valueColumnT let-data="rowValue" let-value="cellValue">
        <b>{{ value }} </b>{{ data.amount.currency }}
      </ng-template>

      <ng-template #statusColumnT let-data="rowValue" let-value="cellValue">
        <span
          *ngIf="value.value === 'NOT_PAID' && !data.payable"
          class="status statusPAY_INACTIVE"
          [ppfTooltip]="labels.statusUnpaid | flxLocalize"
          [tooltipPosition]="'top'"
          >{{ labels.payAction | flxLocalize }}</span
        >
        <span
          *ngIf="value.value === 'NOT_PAID' && data.payable"
          class="status statusPAY_ACTIVE cursor-pointer"
          (click)="payInvoice(data.invoiceId, data.type)"
          >{{ labels.payAction | flxLocalize }}</span
        >
        <span
          *ngIf="value.value === 'PROCESSING'"
          [ppfTooltip]="labels.statusProcessing | flxLocalize"
          [ngClass]="'status status' + value.value"
          >{{ value.label | flxLocalize }}</span
        >
        <span
          *ngIf="value.value !== 'NOT_PAID' && value.value !== 'PROCESSING'"
          [ngClass]="'status status' + value.value"
          >{{ value.label | flxLocalize }}</span
        >
      </ng-template>
    </omc-table>
  </ppf-card>
</div>
<div *ngIf="invoices && invoices.length === 0" fxLayout="column" fxLayoutAlign="center center" fxFill>
  <img src="../assets/images/empty-state.svg" alt="empty state image" class="empty-state-image" />
  <p>{{ labels.emptyStateInvoices | flxLocalize }}</p>
</div>
