<ppf-modal class="omc-modal">
  <omc-notification-details [notification]="data.notification" [showButton]="false" [showAllText]="true">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="small-header">
      <h3>{{ data.title }}</h3>
      <button class="modal-close" (click)="closeModal()">
        <ppf-icon name="close"></ppf-icon>
      </button>
    </div>
  </omc-notification-details>
</ppf-modal>
