import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SidebarUserInfo } from '@app/main/menu/dictionary';
import { AppEventsService } from '@app/shared/services/app-events.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';

@Component({
  selector: 'omc-account-main',
  templateUrl: './account-main.component.html',
  styleUrls: ['./account-main.component.scss'],
})
export class AccountMainComponent implements OnInit, OnDestroy {
  @Input() data$: Observable<any>;
  public reducedData$: Observable<any>;
  private appEvent$ = this.appEventsService.appEvent$;
  public accountInfo: SidebarUserInfo;
  public selectedIndex: number;

  constructor(private appEventsService: AppEventsService, private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.googleAnalyticsService.eventEmitter('My account', 'in_app_page_view', 'my_account_screen', 'view_loaded', 1);

    this.reducedData$ = this.appEventsService.userEventsInit(this.data$, 'account', 'collaborationAccountInformation');

    this.appEvent$.subscribe((data) => {
      this.accountInfo = data.accountInfo;
    });
  }

  show(index): void {
    this.selectedIndex = index;
  }

  ngOnDestroy(): void {
    this.appEventsService.unsubscribeUserEvents();
  }
}
