import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import moment from 'moment';
import _ from 'lodash';

import { Column } from '@app/main/omv-table/table.dictionary';
import { Payment } from './interfaces';
import { ExportModalComponent } from '@app/main/export-modal/export-modal.component';
import { ModalService } from 'paperflow-web-components';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';

@Component({
  selector: 'omc-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit, OnChanges {
  @Input() data: Array<Payment>;

  public payments;
  public initialPayments: Array<Payment>;
  public columns: Array<Column> = [];
  public labels: any;
  modal: any;

  constructor(private modalService: ModalService, private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.googleAnalyticsService.eventEmitter('Payments', 'in_app_page_view', 'payments_screen', 'tab_loaded', 1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.labels = changes.data.currentValue.labels;
      this.columns = [
        { label: this.labels.paymentDate, id: 'paymentDateFormatted', direction: 'ascendant', selected: false },
        { label: this.labels.paymentValue, id: 'amount', direction: 'ascendant', selected: false },
      ];
      this.setPayments(changes.data.currentValue);
    }
  }

  setPayments(data): void {
    this.initialPayments = data.payments
      ? data.payments.map((payment) => {
          payment.paymentDateFormatted = moment(payment.paymentDate).format('DD.MM.YYYY');

          return payment;
        })
      : null;

    this.payments = this.initialPayments;
  }

  sort(event): void {
    const sortedPayments = _.sortBy(this.payments, (cv) => {
      switch (event.id) {
        case 'amount':
          return cv.amount.value;
        case 'paymentDateFormatted':
          return new Date(cv.paymentDate).getTime();
        default:
          return cv[event.id];
      }
    });
    this.payments = event.direction === 'descendant' ? sortedPayments.reverse() : sortedPayments;
  }

  handleCsvExport(): void {
    this.modal = this.modalService.show(
      ExportModalComponent,
      {
        closeOnBackdropClick: false,
      },
      {
        title: this.labels.exportCSV.title,
        subtitle: this.labels.exportCSV.subtitle,
        btnLabel: this.labels.exportCSV.btnLabel,
        columns: this.columns,
        list: this.payments,
      },
    );
  }
}
