<ppf-card class="notification mb-16 p-0" fxLayout="column">
  <ng-content></ng-content>

  <omc-notification-body
    [id]="notification.id"
    [imgSrc]="'../assets/images/notification.svg'"
    [title]="notification.title"
    [message]="notification.message"
    [date]="notification.dateFormatted"
    [showAllText]="showAllText"
    [showButton]="showButton"
    (openModal)="openModal()"
  >
  </omc-notification-body>
</ppf-card>
