import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppEventsService } from '@app/shared/services/app-events.service';
import { Location } from '@angular/common';

@Component({
  selector: 'omc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private redirectToOnbEvent$ = this.appEventsService.redirectToOnb$;
  private onBaseUrl = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appEventsService: AppEventsService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.redirectToOnbEvent$.subscribe((data) => {
      this.onBaseUrl = data.baseUrl;
    });

    this.route.queryParams.subscribe((queryParams) => {
      if (this.onBaseUrl && queryParams.processUuid) {
        localStorage.removeItem('processInstances');
        this.location.replaceState(this.location.path().slice(0, this.location.path().indexOf('?')));
        window.open(this.onBaseUrl + '?processUuid=' + queryParams.processUuid, '_self');
      }
    });
  }
}
