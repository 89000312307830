<div *ngIf="accountInfo && reducedData$ | async as data" class="collaboration-page account-page">
  <div *ngIf="!selectedIndex" class="mt-32">
    <div fxLayout="row" fxLayoutAlign="start center" class="mt-24 mb-16">
      <h1 class="page-title">{{ data.labels.title }}</h1>
    </div>
    <div fxLayout="row">
      <div *ngFor="let item of data.sections; let i = index" fxFlex="33">
        <ppf-card fxLayout="column" fxLayoutAlign="space-between center" class="mr-16 pr-16 pl-16">
          <div fxLayout="column" fxLayoutAlign="start center">
            <img
              [src]="'../assets/images/account/account' + i + '.svg'"
              alt="account card image"
              class="mt-16 account-card-image"
            />
            <h3 class="account-card-title mt-16">{{ item.title | flxLocalize }}</h3>
            <p class="text-center">{{ item.description | flxLocalize }}</p>
          </div>
          <ppf-button type="flat" leftIconName="buttonArrowRight" (onClick)="show(i + 1)"></ppf-button>
        </ppf-card>
      </div>
    </div>
  </div>
  <div *ngIf="selectedIndex" fxLayout="column" fxFill>
    <div fxLayout="row" fxLayoutAlign="start center" class="mt-24">
      <ppf-button class="back-button" type="flat" leftIconName="buttonArrowLeft" (onClick)="show(null)"></ppf-button>
      <h1 class="page-title">{{ data.sections[selectedIndex - 1].title | flxLocalize }}</h1>
    </div>
    <omc-personal-info *ngIf="selectedIndex === 1" fxFlex [data]="data.personalInfo"></omc-personal-info>
    <omc-notification-settings
      *ngIf="selectedIndex === 2"
      fxFlex
      [initialData]="data.notificationSettings"
      [actionsFn]="data.actionsFn"
      [actionMetadata]="data.actionMetadata"
    ></omc-notification-settings>
    <omc-user-documents
      *ngIf="selectedIndex === 3"
      fxFlex
      [data]="data.documents"
      [labels]="data.labels"
    ></omc-user-documents>
  </div>
</div>
