import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import _ from 'lodash';

import { SidebarUserInfo } from '@app/main/menu/dictionary';
import { AppEventsService } from '@app/shared/services/app-events.service';
import { ConsumptionValue } from '@app/main/consumption-points/interfaces';
import { Utils } from '@app/shared/services/utils';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { Ticket } from '@app/main/tickets/interfaces';

@Component({
  selector: 'omc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Input() data$: Observable<any>;
  private appEvent$ = this.appEventsService.appEvent$;
  public accountInfo: SidebarUserInfo;
  public reducedData$: Observable<any>;

  public consumptionValues: Array<ConsumptionValue>;
  public initialConsumptionValues: Array<ConsumptionValue>;

  public filterItems: Array<any>;
  public selectedMonth = '';

  public barChartLabels: Array<any> = [];
  public barChartData: Array<any> = [];

  constructor(
    private appEventsService: AppEventsService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.googleAnalyticsService.eventEmitter('Dashboard', 'in_app_page_view', 'dashboard_screen', 'view_loaded', 1);
    this.reducedData$ = this.appEventsService.userEventsInit(
      this.data$,
      'dashboard',
      'collaborationDashboardInformation',
    );

    this.appEvent$.subscribe((data) => {
      this.accountInfo = data.accountInfo;
    });

    this.reducedData$.subscribe((data) => {
      const chartData = Utils.setChartValues(
        data.invoices || [],
        data.labels && data.labels.chart ? data.labels.chart.invoiceHistoryLabel : '',
        data.forecastInvoices,
        false,

        true,
        data.labels && data.labels.chart ? data.labels.chart.forecastLabel : '',
      );

      this.barChartData = chartData.barChartData;
      this.barChartLabels = chartData.barChartLabels;
    });
  }

  sortTickets(tickets): Array<Ticket> {
    return tickets.slice(0, 3);
  }

  redirectToInvoices(isGas: boolean): void {
    this.renderer.addClass(document.body, 'child-view-loader');
    this.renderer.addClass(document.body, 'hide-loading-backdrop');

    this.appEventsService.setActiveMenuItem({ activeMenuItem: isGas ? 'gas' : 'power' });
    this.router.navigate(['main', isGas ? 'gas' : 'power']);
  }

  getTicketsAction(data): any {
    return data.actionsFn.add_support_ticket;
  }

  getTicketsActionLabel(data): string {
    const createTicketAction = _.filter(data.actionMetadata, (action) => action.name === 'add_support_ticket');
    return createTicketAction.length ? createTicketAction[0].label : '';
  }

  ngOnDestroy(): void {
    this.appEventsService.unsubscribeUserEvents();
  }
}
