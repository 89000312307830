export interface SidebarLink {
  label: string;
  icon: string;
  route: string;
}

export interface SidebarUserInfo {
  type: string;
  accountBalance: Array<{
    amount: {
      value: number;
      currency: string;
    };
    type: string;
  }>;
  labels: any;
  name: string;
  payAction: any;
  addMeterReading: any;
  route: string;
  enableIndexReading: boolean;
}

export const ROUTES = {
  collaborationDashboardInformation: 'dashboard',
  collaborationGasInformation: 'gas',
  collaborationPowerInformation: 'power',
  collaborationAccountInformation: 'account',
  collaborationSupportInformation: 'support',
  collaborationNotificationsInformation: 'notifications',
};
