import { Component, Inject, OnInit } from '@angular/core';
import { MODAL_DATA, ModalService } from 'paperflow-web-components';
import { ExportFileService } from '@app/shared/services/export-file.service';
import { FormControl, FormGroup } from '@angular/forms';
import { OMV_VALIDATORS } from '@app/shared/validators';

@Component({
  selector: 'omc-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss'],
})
export class ExportModalComponent implements OnInit {
  public toggleForm: FormGroup = new FormGroup({}, OMV_VALIDATORS.minimOneTrue());
  public exportOptions: Array<any>;
  public title: string;
  public subtitle: string;
  public btnLabel: string;
  private exportData: Array<any>;
  private exportColumns: Array<any>;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      list: Array<any>;
      columns: Array<any>;
      title: string;
      subtitle: string;
      btnLabel: string;
    },
    private exportFileService: ExportFileService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.exportData = this.data.list || [];
      this.exportColumns = this.data.columns || [];
      this.title = this.data.title || 'Export to CSV file';
      this.subtitle = this.data.subtitle || 'Choose what details to include in exported data';
      this.btnLabel = this.data.btnLabel || 'Download file';
      this.exportOptions = this.getExportOptions();
      this.createForm();
    }
  }

  createForm(): void {
    this.exportOptions.forEach((option) => {
      this.toggleForm.addControl(option.key, new FormControl(true));
    });
  }

  doAction(): void {
    const idKeys = this.filterAllowedAttributes();

    const mappedExportData = this.exportData.map((data) => {
      const dataRow = {};
      Object.entries(idKeys as string | number).forEach(([key, index]) => {
        if (data[key] && key !== 'actions') {
          dataRow[index] = data[key].value ? data[key].value : data[key];
        }
      });
      return dataRow;
    });

    this.exportFileService.downloadFile(mappedExportData);
    this.closeModal();
  }

  getExportOptions(): any {
    return this.exportColumns
      .filter((item) => item.id !== 'actions')
      .map((item) => ({
        key: item.id,
        label: item.label,
        description: item.description,
      }));
  }

  filterAllowedAttributes(): any {
    return this.exportColumns.reduce((first, second) => {
      if (this.toggleForm.value[second.id]) {
        first[second.id] = second.label;
      }
      return first;
    }, {});
  }

  closeModal(): void {
    this.modalService.hide();
  }
}
