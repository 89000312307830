import { Component } from '@angular/core';
import * as copywrite from '../../assets/global.json';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';

@Component({
  selector: 'omc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public texts = copywrite.footer;
  public showPrivacy = false;

  constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

  openPrivacy(name): void {
    document.body.classList.add('no-scroll');
    this.showPrivacy = true;

    this.googleAnalyticsService.eventEmitter(
      'Open hyperlink',
      'custom_events',
      'open_external_link',
      name + '_click',
      1,
    );
  }

  openUrl(url, name): void {
    this.googleAnalyticsService.eventEmitter(
      'Open hyperlink',
      'custom_events',
      'open_external_link',
      name + '_click',
      1,
    );
    window.open(url, '_blank');
  }
}
