<div fxLayout="row" class="ticket-wrapper" [ngClass]="{ 'ticket-green': status === 'CLOSED' }">
  <img [src]="imgSrc" alt="tickets status" class="ticket-status-image mr-16" />
  <div class="ticket-content">
    <div fxLayout="row" fxLayoutAlign="space-between start" class="ticket-header">
      <div class="ticket-title" fxLayout="row">
        <h3>{{ title }}</h3>
        <span *ngIf="id" class="ml-4 ticket-id">{{ '(#' + id + ')' }}</span>
      </div>
      <div fxLayout="row" class="ticket-actions">
        <div class="open-details-button mr-16">
          <ppf-button
            *ngIf="showButton"
            type="flat"
            leftIconName="magnifier"
            (onClick)="triggerOpenModal()"
          ></ppf-button>
        </div>
        <span *ngIf="showStatus" class="ticket-status" [ngClass]="'ticket-status-' + status">{{ label }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start-center">
      <ppf-icon name="calendar"></ppf-icon>
      <span class="ml-8">{{ date }}</span>
    </div>
    <p [ngClass]="{ 'ticket-message': !showAllText }">{{ message }}</p>
  </div>
</div>
