import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'omc-notification-body',
  templateUrl: './notification-body.component.html',
  styleUrls: ['./notification-body.component.scss'],
})
export class NotificationBodyComponent {
  @Input() status: string;
  @Input() imgSrc: string;
  @Input() id = '';
  @Input() title: string;
  @Input() message: string;
  @Input() date: string;
  @Input() showButton = true;
  @Input() showAllText: boolean;
  @Output() openModal = new EventEmitter<any>();

  constructor() {}

  triggerOpenModal(): void {
    this.openModal.emit();
  }
}
