import { Component, Input, OnInit } from '@angular/core';
import { PersonalInfo } from '@app/main/personal-info/interfaces';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';

@Component({
  selector: 'omc-personal-info',
  templateUrl: './personal-info.component.html',
})
export class PersonalInfoComponent implements OnInit {
  @Input() data: PersonalInfo;

  constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.googleAnalyticsService.eventEmitter(
      'Personal Info',
      'in_app_page_view',
      'personal_info_screen',
      'account_card_clicked',
      1,
    );
  }
}
