import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ROUTES, SidebarUserInfo } from '@app/main/menu/dictionary';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastService } from 'paperflow-web-components';
import * as copywrite from '../../../assets/global.json';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppEventsService {
  private texts = copywrite.appEvents;
  private appEventSubject = new BehaviorSubject<{ accountInfo: any }>({
    accountInfo: null,
  });
  readonly appEvent$ = this.appEventSubject.asObservable();

  private redirectToOnbSubject = new BehaviorSubject<{ baseUrl: string }>({
    baseUrl: '',
  });
  readonly redirectToOnb$ = this.redirectToOnbSubject.asObservable();

  private activeMenuItemSubject = new BehaviorSubject<{ activeMenuItem: string }>({ activeMenuItem: '' });
  readonly activeMenuItem$ = this.activeMenuItemSubject.asObservable();
  private activeMenuItem = '';
  private paymentSubscriber;
  private accountInfoSubscriber;
  public renderer: Renderer2;

  constructor(
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private toastService: ToastService,
    private router: Router,
    private _renderer: RendererFactory2,
    @Inject('BASE_API_URL') public baseApiUrl: string,
    @Inject('PROCESS_API_PATH') public processApiPath: string,
  ) {
    this.renderer = this._renderer.createRenderer(null, null);
    this.renderer.addClass(document.body, 'hide-loading-backdrop');
  }

  setUserAccountInfo(value: { accountInfo: SidebarUserInfo | null }): void {
    this.appEventSubject.next(value);
  }

  setOnbRedirectLink(value: { baseUrl: string }): void {
    this.redirectToOnbSubject.next(value);
  }

  setActiveMenuItem(value: { activeMenuItem: string }): void {
    this.activeMenuItem = value.activeMenuItem;
    this.activeMenuItemSubject.next(value);
  }

  getPaymentParams(): { childProcessInstanceId: string; childToken: string; action: string; paymentStarted } {
    const action = this.route.snapshot.queryParams.action;
    const childToken = this.route.snapshot.queryParams.childToken;
    const childProcessInstanceId = this.route.snapshot.queryParams.childProcessInstanceId;
    const paymentStarted = this.route.snapshot.queryParams.paymentStarted;

    return {
      action,
      childToken,
      childProcessInstanceId,
      paymentStarted,
    };
  }

  getUserAccountInfo(data$, key): void {
    this.accountInfoSubscriber = data$.subscribe((data) => {
      if (data && data[key]) {
        if (data[key].sideBarUserInfo) {
          this.setUserAccountInfo({
            accountInfo: {
              type: data[key].sideBarUserInfo.type,
              name: data[key].sideBarUserInfo.name,
              accountBalance: data[key].sideBarUserInfo.accountBalance,
              payAction: data.actionsFn ? data.actionsFn.open_payment_modal : null,
              addMeterReading: data.actionsFn ? data.actionsFn.open_index_reading_modal : null,
              route: key,
              labels: data[key].sideBarUserInfo.labels,
              enableIndexReading: data[key].enableIndexReading || false,
            },
          });
          if (!this.activeMenuItem) {
            this.setActiveMenuItem({
              activeMenuItem: ROUTES[key],
            });
          }
        } else {
          this.setUserAccountInfo({
            accountInfo: null,
          });
        }

        if (this.activeMenuItem === ROUTES[key]) {
          this.renderer.removeClass(document.body, 'hide-loading-backdrop');
          this.renderer.removeClass(document.body, 'child-view-loader');
        }
      }
    });
  }

  paymentCallback(): void {
    if (
      this.getPaymentParams().action === 'confirm_payment' &&
      this.getPaymentParams().childProcessInstanceId &&
      this.getPaymentParams().childToken
    ) {
      this.httpClient
        .post(
          `${this.baseApiUrl}${this.processApiPath}/api/process/${
            this.getPaymentParams().childProcessInstanceId
          }/token/${this.getPaymentParams().childToken}/action/confirm_payment/execute`,
          {},
        )
        .subscribe();
    }
  }

  paymentCancelledCallback(route): void {
    if (this.getPaymentParams().paymentStarted) {
      localStorage.removeItem('processInstances');
      this.router.navigate(['main', route], { queryParams: {} }).then(() => {
        window.location.reload();
      });
    }
  }

  userEventsInit(data$, route, key): Observable<any> {
    this.getUserAccountInfo(data$, key);

    this.paymentSubscriber = data$.subscribe((data) => {
      if (localStorage.getItem('showPaymentSuccess')) {
        this.toastService.show({ messageType: 'success', message: this.texts.paymentSuccess });
        localStorage.removeItem('showPaymentSuccess');
      }

      if (data && data[key] && data[key].paymentConfirmed) {
        this.renderer.addClass(document.body, 'child-view-loader');
        this.renderer.addClass(document.body, 'hide-loading-backdrop');

        localStorage.setItem('showPaymentSuccess', 'true');
        localStorage.removeItem('processInstances');

        this.router.navigate(['main', route], { queryParams: {} }).then(() => {
          window.location.reload();
        });
      }
    });

    return data$.pipe(
      map((data: any) => ({
        actions: data.actions,
        actionsFn: data.actionsFn,
        ...data[key],
      })),
    );
  }

  unsubscribeUserEvents(): void {
    this.paymentSubscriber.unsubscribe();
    this.accountInfoSubscriber.unsubscribe();
  }
}
