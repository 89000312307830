import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  AccordionModule,
  AvatarModule,
  ButtonModule,
  CardModule,
  ChipModule,
  IconModule,
  HintModule,
  MessageModule,
  SpinnerModule,
  ModalModule,
  MenuModule,
  BadgeModule,
  ListModule,
  InputModule,
  SelectModule,
  SharedModule as PpfShared,
  ListItemModule,
  TooltipModule,
  DatePickerModule,
  CheckboxModule,
  RadioModule,
  SwitchModule,
  TableModule,
  ToastModule,
} from 'paperflow-web-components';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ChartModule } from '@app/shared/chart-directive/chart.module';
import { FlxLocalizationModule } from 'flowx-process-renderer';

@NgModule({
  imports: [CommonModule, DatePickerModule.forRoot()],
  // TODO remove unused components
  exports: [
    FlexLayoutModule,
    ButtonModule,
    CardModule,
    CheckboxModule,
    ChipModule,
    SpinnerModule,
    BadgeModule,
    MessageModule,
    IconModule,
    HintModule,
    ModalModule,
    ListModule,
    ListItemModule,
    OverlayModule,
    TooltipModule,
    PpfShared,
    InputModule,
    DatePickerModule,
    ReactiveFormsModule,
    FormsModule,
    AvatarModule,
    MenuModule,
    SelectModule,
    AccordionModule,
    RadioModule,
    SwitchModule,
    TableModule,
    PdfViewerModule,
    ChartModule,
    ToastModule,
    FlxLocalizationModule,
  ],
  providers: [Overlay],
})
export class SharedModule {}
