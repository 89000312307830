export class CustomTooltipService {
  public static createCustomTooltip(tooltipModel, chart, type = 'default'): any {
    let tooltipEl = document.getElementById('chartjs-tooltip');
    const yAlign = tooltipModel.yAlign;

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<table></table>';
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0 as any;
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove('top', 'above', 'bottom', 'center', 'left', 'right');
    tooltipEl.classList.add(tooltipModel.yAlign);
    tooltipEl.classList.add(tooltipModel.xAlign);

    const getBody = (bodyItem) => bodyItem.lines;

    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(getBody);

      let innerHtml = '<thead>';
      titleLines.forEach((title) => {
        innerHtml +=
          '<tr><th style="padding-bottom: 16px" colspan="3" style="text-align: center">' + title + '</th></tr>';
      });
      innerHtml += '</thead><tbody>';

      if (type === 'invoice') {
        let hasGas = false;
        let hasPower = false;
        bodyLines.forEach((body) => {
          if (body && body.length) {
            body.forEach((line) => {
              if (line.type === 'GAS' && line.value) {
                hasGas = true;
              }
              if (line.type === 'POWER' && line.value) {
                hasPower = true;
              }
            });
          }
        });

        bodyLines.forEach((body) => {
          if (body && body.length) {
            body.forEach((line) => {
              if (line.type === 'GAS' && line.value) {
                let style = 'float: right';
                style += '; color: #85D633';
                style += '; font-weight: bold;';
                let labelStyle = 'color: #85D633';
                labelStyle += '; font-weight: bold';
                const label = '<span style="' + labelStyle + '">' + 'GAS' + '</span>';
                const span = '<span style="' + style + '">' + line.value + '</span>';
                innerHtml +=
                  '<tr><td style="font-weight: 600">' +
                  line.label +
                  '&nbsp;' +
                  label +
                  '</td><td>' +
                  span +
                  '</td></tr>';
              }
            });
          }
        });

        if (hasGas && hasPower) {
          innerHtml += '<tr><td style="font-weight: 600">' + '&nbsp;' + '</td><td>' + '&nbsp;' + '</td></tr>';
        }

        bodyLines.forEach((body) => {
          if (body && body.length) {
            body.forEach((line) => {
              if (line.type === 'POWER' && line.value) {
                let style = 'float: right;';
                style += '; color: #B3C2D1';
                style += '; font-weight: bold';
                let labelStyle = 'color: #8099B2';
                labelStyle += '; font-weight: bold';

                const label = '<span style="' + labelStyle + '">' + 'POWER' + '</span>';
                const span = '<span style="' + style + '">' + line.value + '</span>';
                innerHtml +=
                  '<tr><td style="font-weight: 600">' +
                  line.label +
                  '&nbsp;' +
                  label +
                  '</td><td>' +
                  span +
                  '</td></tr>';
              }
            });
          }
        });
      } else {
        bodyLines.forEach((body) => {
          if (body && body.length) {
            body.forEach((line) => {
              let style = 'float: right;';
              style += '; color: #ffdd00';
              style += '; font-weight: bold';
              const span = '<span style="' + style + '">' + line.value + '</span>';
              innerHtml += '<tr><td style="font-weight: 600">' + line.label + '</td><td>' + span + '</td></tr>';
            });
          }
        });
      }

      innerHtml += '</tbody>';

      const tableRoot = tooltipEl.querySelector('table');
      if (tableRoot) {
        tableRoot.innerHTML = innerHtml;
      }
    }

    const { height, width } = tooltipEl.getBoundingClientRect();

    // Chart canvas positions
    const positionY = chart.chart.canvas.offsetTop;
    const positionX = chart.chart.canvas.offsetLeft;

    // Carets
    const caretY = tooltipModel.caretY;
    const caretX = tooltipModel.caretX;

    // Final coordinates
    let top = positionY + caretY - height;
    const left = positionX + caretX - width / 2;

    const space = 12;
    if (yAlign === 'bottom') {
      top -= space;
    }

    // Left and right
    tooltipEl.style.top = `${top}px`;
    tooltipEl.style.left = `${left}px`;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1 as any;
    tooltipEl.style.backgroundColor = '#333e48';
    tooltipEl.style.color = '#fff';
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.padding = '16px';
    tooltipEl.style.borderRadius = '8px';
  }
}
