import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';

import { AddMeterInput, ERRORS } from './dictionary';
import { OMV_VALIDATORS } from '@app/shared/validators';
import { LoaderService } from '@app/shared/services/loader.service';
import * as copywrite from '../../../assets/global.json';

@Component({
  selector: 'omc-add-meter-reading',
  templateUrl: './add-meter-reading.component.html',
  styleUrls: ['./add-meter-reading.component.scss'],
})
export class AddMeterReadingComponent implements OnInit, OnDestroy {
  @Input() data$: Observable<AddMeterInput>;
  public addMeterForm: FormGroup = new FormGroup({});
  public modalData$: Observable<AddMeterInput>;
  private addMeterFormSubmitted = false;
  public texts = copywrite.indexReading;

  constructor(private loader: LoaderService) {}

  ngOnInit(): void {
    this.loader.setLoaderState({ loading: false, type: 'normal' });
    this.modalData$ = this.data$.pipe(
      tap((data) => {
        if (data && data.collaborationAddReadingInformation) {
          this.createForm(data.collaborationAddReadingInformation.indexReading);
        }
      }),
    );
  }

  createForm(data): void {
    data?.indexValues?.forEach((indexValue) => {
      this.addMeterForm.addControl(
        indexValue.consumptionPointId,
        new FormControl(indexValue.currentIndexValue, [
          Validators.maxLength(8),
          Validators.pattern('^[0-9]*$'),
          OMV_VALIDATORS.higherThanPrevious(indexValue.previousIndexValue),
        ]),
      );
    });
  }

  showErrors(control: AbstractControl): any {
    return this.addMeterFormSubmitted && control.errors ? ERRORS[Object.keys(control.errors as any)[0]] : false;
  }

  doAction(data): void {
    this.addMeterFormSubmitted = true;

    if (!this.addMeterForm.valid) {
      return;
    }

    data.actionsFn.save_index_reading({ meterReadings: this.addMeterForm.value }).subscribe();
  }

  getActionLabel(data): string {
    const addReadingAction = _.filter(data.actionsMetadata, (action) => action.name === 'submit_index');
    return addReadingAction.length ? addReadingAction[0].label : '';
  }

  ngOnDestroy(): void {
    document.body.classList.remove('no-scroll');
  }

  closeModal(data): void {
    data.actionsFn.dismiss_process().subscribe();
  }
}
