<ppf-modal *ngIf="modalData$ | async as data" class="omc-modal pay-all-modal">
  <ppf-card *ngIf="data.collaborationPayAllInformation">
    <div fxLayout="row" fxLayoutAlign="end center" class="title pr-24 pt-24">
      <button class="modal-close" (click)="closeModal(data)">
        <ppf-icon name="close"></ppf-icon>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="title pr-32 pl-32">
      <h2 class="pay-all-modal-title text-center">{{ data.collaborationPayAllInformation.title | flxLocalize }}</h2>
    </div>
    <div class="invoices-section">
      <div
        *ngFor="let invoice of data.collaborationPayAllInformation.invoices"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="mt-24 pr-32 pl-32"
      >
        <div class="invoice-section">
          <div class="pay-all-modal-subtitle invoice-id text-bold">{{ invoice.invoiceId }}</div>
          <div fxLayout="row" class="mt-12">
            <ppf-icon name="calendar" class="mr-12"></ppf-icon>
            <span>{{ invoice.invoiceMonth }}</span>
          </div>
          <div class="mt-8">
            <div fxLayout="row">
              <ppf-icon name="money" class="mr-8"></ppf-icon>
              <span class="grey" *ngIf="invoice.remainingSum">
                <span class="text-bold">{{data.collaborationPayAllInformation.labels.totalAmountLabel | flxLocalize}}:&nbsp;{{ invoice.totalAmount}}&nbsp;</span>
                  {{ invoice.amount.currency }}
                </span>
              <span *ngIf="!invoice.totalAmount">
              <span class="text-bold">{{ invoice.amount.value + ' ' }}</span>
                {{ invoice.amount.currency }}
              </span>
            </div>
            <div *ngIf="invoice.remainingSum" class="pl-24">
              <div fxLayout="row" class="mt-6">
                <span class="text-bold">
                  {{data.collaborationPayAllInformation.labels.remainingSumLabel | flxLocalize}}:&nbsp;{{invoice.remainingSum}}&nbsp;
                </span>
                {{ invoice.amount.currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="total-container mt-32">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="pl-32 pr-32">
        <div class="pay-all-modal-subtitle text-bold">
          {{ data.collaborationPayAllInformation.total.label | flxLocalize }}
        </div>
        <div fxLayout="row">
          <span class="text-bold pay-all-modal-subtitle"
            >{{ data.collaborationPayAllInformation.total.amount.value }}&nbsp;</span
          >
          <span class="text-currency pay-all-modal-subtitle">{{
            data.collaborationPayAllInformation.total.amount.currency | flxLocalize
          }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="data.actionsFn.pay_action" fxLayout="row" fxLayoutAlign="center center" class="pb-32">
      <ppf-button
        [title]="
          getActionLabel(data.collaborationPayAllInformation, data.collaborationPayAllInformation.invoices.length > 1)
            | flxLocalize
        "
        (onClick)="doAction(data)"
        class="mt-32"
        type="fill"
      ></ppf-button>
    </div>
  </ppf-card>
</ppf-modal>
