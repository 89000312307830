<div *ngIf="consumptionValues" class="collaboration-page mt-32">
  <h1 class="page-title mr-48">{{ labels.title }}</h1>
  <div *ngIf="consumptionValues.length">
    <div fxLayout="row">
      <ppf-card fxLayout="column" fxFlex="50" class="bar-chart-card pl-16 pr-16 mr-16">
        <h2 class="headline pb-24 pl-8 pt-24">{{ labels.firstTitle | flxLocalize }}</h2>
        <omc-consumption-value-bar-chart
          [data]="barChartData"
          [labels]="barChartLabels"
        ></omc-consumption-value-bar-chart>
      </ppf-card>

      <ppf-card fxLayout="column" fxFlex="50" class="bar-chart-card pb-16">
        <h2 class="headline pb-24 pl-24 pt-24">{{ (labels.secondTitle | flxLocalize) + selectedMonth }}</h2>
        <omc-consumption-value-pie-chart
          [data]="pieChartData"
          [labels]="pieChartLabels"
        ></omc-consumption-value-pie-chart>
      </ppf-card>
    </div>

    <ppf-card fxLayout="column" class="history-card">
      <h2 class="headline">{{ labels.thirdTitle | flxLocalize }}</h2>
      <div fxLayout="row" class="table-actions" fxLayoutAlign="space-between center">
        <ppf-select
          *ngIf="filterItems.length > 1"
          [options]="filterItems"
          [placeholder]="labels.filterPlaceHolder"
          [label]="labels.filterPlaceHolder"
          (selectChange)="handleFilterChange($event)"
          class="filter-select"
        >
        </ppf-select>
        <div fxLayout="row" class="link-button export-csv-section">
          <ppf-icon name="download"></ppf-icon>
          <a (click)="handleCsvExport()" class="semibold">{{ labels.exportLabel }}</a>
        </div>
      </div>
      <omc-table
        [data]="consumptionValues"
        [columns]="columns"
        [notSortableColumnsIds]="[]"
        [templates]="{ consumption: consumptionColumnT, type: typeColumnT }"
        (sortClicked)="sort($event)"
      >
        <ng-template #consumptionColumnT let-data="rowValue" let-value="cellValue">
          <b>{{ data.consumption.value }} </b>{{ data.consumption.unit }}
        </ng-template>

        <ng-template #typeColumnT let-data="rowValue" let-value="cellValue">
          <span class="status">{{ value.label | flxLocalize }}</span>
        </ng-template>
      </omc-table>
    </ppf-card>
  </div>
  <div *ngIf="consumptionValues.length === 0" fxLayout="column" fxLayoutAlign="center center" fxFill>
    <img src="../assets/images/empty-state.svg" alt="empty state image" class="empty-state-image" />
    <p>{{ labels.emptyStateConsumption | flxLocalize }}</p>
  </div>
</div>
