import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MODAL_DATA, ModalService } from 'paperflow-web-components';
import { Ticket } from '@app/main/tickets/interfaces';

@Component({
  selector: 'omc-ticket-details-modal',
  templateUrl: './ticket-details-modal.component.html',
  styleUrls: ['./ticket-details-modal.component.scss'],
})
export class TicketDetailsModalComponent implements OnInit, OnDestroy {
  constructor(@Inject(MODAL_DATA) public data: { ticket: Ticket; title: string }, private modalService: ModalService) {}

  ngOnInit(): void {
    document.body.classList.add('no-scroll');
  }

  closeModal(): void {
    this.modalService.hide();
  }

  ngOnDestroy(): void {
    document.body.classList.remove('no-scroll');
  }
}
