import { Component, Input, Renderer2 } from '@angular/core';

import { Ticket } from '@app/main/tickets/interfaces';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { Router } from '@angular/router';
import { AppEventsService } from '@app/shared/services/app-events.service';

@Component({
  selector: 'omc-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
})
export class TicketsComponent {
  @Input() title: string;
  @Input() tickets: Array<Ticket>;
  @Input() openModal: any = this.redirectToSupport;
  @Input() buttonLabel: string;
  @Input() googleAction: string;
  @Input() emptyStateLabel: string;
  @Input() modalLabel: string;
  public addTicketDelay = true;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private appEventsService: AppEventsService,
    private renderer: Renderer2,
  ) {}

  openModalAction(): void {
    if (this.openModal) {
      if (this.addTicketDelay) {
        this.openModal().subscribe();
        this.sendGoogleEvent();

        this.addTicketDelay = false;

        setTimeout(() => {
          this.addTicketDelay = true;
        }, 500);
      }
    } else {
      this.redirectToSupport();
    }
  }

  sendGoogleEvent(): void {
    this.googleAnalyticsService.eventEmitter('Add new ticket', 'custom_events', 'support_add', this.googleAction, 1);
  }

  redirectToSupport(): void {
    this.renderer.addClass(document.body, 'child-view-loader');
    this.renderer.addClass(document.body, 'hide-loading-backdrop');

    this.appEventsService.setActiveMenuItem({ activeMenuItem: 'support' });
    this.router.navigate(['main', 'support']);
  }
}
