<div *ngIf="localData" class="notifications-settings mt-16">
  <ppf-card
    *ngFor="let section of localData; let i = index"
    class="notification-item mb-16"
    fxLayout="row"
    fxLayoutAlign="start start"
  >
    <img
      [src]="'../../assets/images/notifications/notifications' + i + '.svg'"
      alt="notification setting image"
      class="notification-setting-image"
    />
    <div class="notification-info pl-16" fxLayout="column">
      <h3>{{ section.title | flxLocalize }}</h3>
      <p>{{ section.subtitle | flxLocalize }}</p>
      <div fxLayout="row wrap" class="mt-12">
        <ppf-checkbox *ngFor="let checkbox of section.options" class="pr-16 pb-16">
          <input
            [id]="checkbox.key"
            [(ngModel)]="checkbox.value"
            [checked]="checkbox.value"
            ppfCheckbox
            type="checkbox"
          />
          <span class="checkbox-text text-bold">{{ checkbox.label }}</span>
        </ppf-checkbox>
      </div>
      <div *ngIf="actionsFn && actionsFn[section.name]" fxLayout="row" fxLayoutAlign="end center">
        <ppf-button
          [title]="getActionLabel(section.name) | flxLocalize"
          [disabled]="buttonDisabled(i)"
          class="mt-32"
          type="fill"
          (onClick)="doAction(section.name, i)"
        ></ppf-button>
      </div>
    </div>
  </ppf-card>
</div>
