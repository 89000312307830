import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import * as copywrite from '../../../../assets/global.json';
import { BaseChartDirective } from '@app/shared/chart-directive/base-chart.directive';
import { CustomTooltipService } from '@app/shared/chart-directive/custom-tooltip.service';

@Component({
  selector: 'omc-invoices-bar-chart',
  templateUrl: './invoices-bar-chart.component.html',
  styleUrls: ['./invoices-bar-chart.component.scss'],
})
export class InvoicesBarChartComponent implements AfterViewInit {
  @ViewChild(BaseChartDirective) public _chart;
  @Input() data: ChartDataSets[];
  @Input() labels: Array<string>;
  @Input() options: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          id: 'history',
          gridLines: {
            display: false,
          },
        },
        {
          id: 'forecast',
          display: false,
          offset: true,
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      yAlign: 'bottom',
      xAlign: 'center',
      custom: (tooltipModel) => {
        CustomTooltipService.createCustomTooltip(tooltipModel, this._chart, 'invoice');
      },

      callbacks: {
        title(tooltipItem, data): any {
          const tooltip = tooltipItem[0];
          return data.datasets[tooltip.datasetIndex].title[tooltip.index] || '';
        },
        label(tooltipItem, data): any {
          const gasConsumption = data.datasets[tooltipItem.datasetIndex].gasConsumption[tooltipItem.index] || '';
          const gasAmount = data.datasets[tooltipItem.datasetIndex].gasAmount[tooltipItem.index] || '';
          const powerConsumption = data.datasets[tooltipItem.datasetIndex].powerConsumption[tooltipItem.index] || '';
          const powerAmount = data.datasets[tooltipItem.datasetIndex].powerAmount[tooltipItem.index] || '';
          if (!gasConsumption && !powerConsumption) {
            return;
          }

          if (!gasAmount && !powerAmount) {
            return;
          }

          return [
            {
              label: copywrite.charts.scale.afterLabel.first,
              value: gasConsumption,
              type: 'GAS',
            },
            {
              label: copywrite.charts.scale.label.first,
              value: gasAmount,
              type: 'GAS',
            },
            {
              label: copywrite.charts.scale.afterLabel.first,
              value: powerConsumption,
              type: 'POWER',
            },
            {
              label: copywrite.charts.scale.label.first,
              value: powerAmount,
              type: 'POWER',
            },
          ];
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };
  public chartLoaded = false;
  barChartPlugins = [];

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.chartLoaded = true;
    }, 100);
  }
}
