<ppf-card *ngIf="data; else loading" fxLayout="column" class="mt-16 pl-24 pr-24 personal-info-card">
  <h3 class="mt-24 mb-16">{{ data.title | flxLocalize }}</h3>
  <div *ngIf="data.fields">
    <div class="pt-16">
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div *ngFor="let field of data.fields" [fxFlex]="33" class="pr-16 pb-32 control-container">
          <ppf-input [label]="field.label">
            <input [id]="field.key" [value]="field.value" ppfInput disabled />
          </ppf-input>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" class="info-container pl-16 mt-32">
    <ppf-icon name="info" class="info-icon mr-12"></ppf-icon>
    {{ data.disclaimer | flxLocalize }}
  </div>
</ppf-card>

<ng-template #loading>
  <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
    <ppf-spinner visualType="custom" size="custom" [radius]="90"></ppf-spinner>
  </div>
</ng-template>
