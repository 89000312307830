import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MODAL_DATA, ModalService } from 'paperflow-web-components';
import moment from 'moment';

import { Invoice } from '../invoices/interfaces';
import { HttpClient } from '@angular/common/http';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { Observable } from 'rxjs';
import { LoaderService } from '@app/shared/services/loader.service';
import { ExportFileService } from '@app/shared/services/export-file.service';

@Component({
  selector: 'omc-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss'],
})
export class InvoiceDetailsComponent implements OnInit, OnDestroy {
  public numberOfPages: number;
  public currentPage = 1;
  public docSrc$: Observable<string>;
  public payAction: any;

  constructor(
    @Inject(MODAL_DATA) public data: { invoice: Invoice; payAction: any; labels: any },
    private modalService: ModalService,
    private httpClient: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService,
    private loader: LoaderService,
    private exportFileService: ExportFileService,
  ) {}

  ngOnInit(): void {
    document.body.classList.add('no-scroll');
    this.docSrc$ = this.exportFileService.toBlob(this.data.invoice.invoiceUrl);
    this.payAction = this.data.payAction;
  }

  get invoiceMonth(): string {
    return `${moment(this.data.invoice.invoiceDate).format('DD/MM/YYYY')} - ${moment(this.data.invoice.invoiceDate)
      .add(1, 'month')
      .format('DD/MM/YYYY')}`;
  }

  afterLoadComplete(pdf): void {
    this.numberOfPages = pdf.numPages;
  }

  closeModal(): void {
    this.modalService.hide();
  }

  sendGoogleEvent(): void {
    this.googleAnalyticsService.eventEmitter(
      'Pay invoice',
      'custom_events',
      'pay_invoice',
      'invoice_details_pay_button_clicked',
      1,
    );
  }

  downloadPdf(): void {
    this.exportFileService.downloadPdf(this.data.invoice.invoiceUrl, this.data.invoice.invoiceId);
  }

  doAction(): void {
    this.closeModal();
    this.loader.setLoaderState({ loading: true, type: 'backdrop' });
    this.payAction({
      invoiceId: this.data.invoice.invoiceId,
      type: 'single',
      commodityType: this.data.invoice.type,
    }).subscribe();
    this.sendGoogleEvent();
    document.body.classList.add('no-scroll');
  }

  getTooltipLabel(status): string {
    switch (status) {
      case 'NOT_PAID':
        return this.data.labels.statusUnpaid;
      case 'PROCESSING':
        return this.data.labels.statusProcessing;
      case 'PAID':
      case 'AVANS':
      case 'STORNO':
        return this.data.labels.statusPaid;
    }

    return this.data.labels.statusProcessing;
  }

  ngOnDestroy(): void {
    document.body.classList.remove('no-scroll');
  }
}
