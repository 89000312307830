import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import moment from 'moment';
import _ from 'lodash';

import { ForecastInvoice, Invoice, InvoicesInput } from './interfaces';
import { Column } from '@app/main/omv-table/table.dictionary';
import { ModalService } from 'paperflow-web-components';
import { InvoiceDetailsComponent } from '../invoice-details/invoice-details.component';
import { ExportModalComponent } from '@app/main/export-modal/export-modal.component';
import { Utils } from '@app/shared/services/utils';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { LoaderService } from '@app/shared/services/loader.service';
import { ExportFileService } from '@app/shared/services/export-file.service';

@Component({
  selector: 'omc-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit, OnChanges {
  @Input() data: InvoicesInput;
  @Input() actionsFn: any;
  public payAction: any;
  public invoices;
  public initialInvoices: Array<Invoice>;
  public columns: Array<Column> = [];
  public filterItems: Array<any>;
  public forecastInvoices: Array<ForecastInvoice> = [];
  public barChartLabels: Array<any> = [];
  public barChartData: Array<any> = [];
  public labels;
  public hasAggregatedInvoices: boolean;
  modal: any;

  constructor(
    private modalService: ModalService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private loader: LoaderService,
    private exportFileService: ExportFileService,
  ) {}

  ngOnInit(): void {
    this.googleAnalyticsService.eventEmitter('Invoices', 'in_app_page_view', 'invoices_screen', 'tab_loaded', 1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      if (!this.labels) {
        this.labels = changes.data.currentValue.labels;
        this.columns = [
          {
            label: this.labels.columns.invoiceMonth,
            description: this.labels.columnsDescriptions.invoiceMonth,
            id: 'invoiceMonth',
            direction: 'ascendant',
            selected: false,
          },
          {
            label: this.labels.columns.invoiceId,
            description: this.labels.columnsDescriptions.invoiceId,
            id: 'invoiceId',
            direction: 'ascendant',
            selected: false,
          },
          {
            label: this.labels.columns.issueDate,
            description: this.labels.columnsDescriptions.issueDate,
            id: 'issueDateFormatted',
            direction: 'ascendant',
            selected: false,
          },
          {
            label: this.labels.columns.dueDate,
            description: this.labels.columnsDescriptions.dueDate,
            id: 'dueDateFormatted',
            direction: 'ascendant',
            selected: false,
          },
          {
            label: this.labels.columns.value,
            description: this.labels.columnsDescriptions.value,
            id: 'value',
            direction: 'ascendant',
            selected: false,
          },
          {
            label: this.labels.columns.status,
            description: this.labels.columnsDescriptions.status,
            id: 'status',
            direction: 'ascendant',
            selected: false,
          },
          { label: this.labels.columns.actions, id: 'actions', direction: 'ascendant', selected: false },
        ];
      }

      this.hasAggregatedInvoices = !!changes.data.currentValue.hasAggregatedInvoices;

      this.setInvoices(changes.data.currentValue);

      this.forecastInvoices = changes.data.currentValue.forecastInvoices;
      const chartValues = Utils.setChartValues(
        changes.data.currentValue.historyInvoices || [],
        changes.data.currentValue.labels && changes.data.currentValue.labels.chart
          ? changes.data.currentValue.labels.chart.firstLegendLabel
          : '',
        this.forecastInvoices,
        this.hasAggregatedInvoices,

        true,
        changes.data.currentValue.labels && changes.data.currentValue.labels.chart
          ? changes.data.currentValue.labels.chart.secondLegendLabel
          : '',
      );

      this.barChartData = chartValues.barChartData;
      this.barChartLabels = chartValues.barChartLabels;
    }

    if (changes.actionsFn && changes.actionsFn.currentValue) {
      this.payAction = changes.actionsFn.currentValue.pay_invoice;
    }
  }

  setInvoices(data): void {
    if (data.historyInvoices) {
      this.initialInvoices = data.historyInvoices.map((invoice) => {
        invoice.issueDateFormatted = moment(invoice.issueDate).format('DD.MM.YYYY');
        invoice.dueDateFormatted = moment(invoice.dueDate).format('DD.MM.YYYY');
        invoice.value = invoice.amount.value;
        return invoice;
      });

      this.invoices = this.initialInvoices;
    }

    const consumptionPoints = data.consumptionPoints || [];

    this.filterItems = [
      { id: 'all', label: data.labels.showAllFilterLabel },
      ...consumptionPoints.map((cp) => {
        cp.value = cp.id;
        return cp;
      }),
    ];
  }

  getRightMenuActions(invoice): Array<any> {
    const actions = [
      {
        label: this.labels ? this.labels.openDetails : '',
        action: () => {
          this.googleAnalyticsService.eventEmitter(
            'Invoice details',
            'in_app_page_view',
            'invoices_table_open_invoice_details',
            'click',
            1,
          );
          this.openModal(invoice);
        },
      },
    ];
    if (invoice.invoiceUrl) {
      actions.push({
        label: this.labels ? this.labels.downloadInvoice : '',
        action: () => {
          this.googleAnalyticsService.eventEmitter(
            'Download invoice from Invoice Details',
            'custom_events',
            'download_invoice',
            'invoices_table_click',
            1,
          );

          this.exportFileService.downloadPdf(invoice.invoiceUrl, invoice.invoiceId);
        },
      });
    }

    return actions;
  }

  openModal(invoice): void {
    this.modal = this.modalService.show(
      InvoiceDetailsComponent,
      {
        closeOnBackdropClick: false,
      },
      { invoice, payAction: this.payAction, labels: this.labels },
    );
  }

  sort(event): void {
    const sortedInvoices = _.sortBy(this.invoices, (invoice) => {
      switch (event.id) {
        case 'status':
          return invoice.status.value === 'NOT_PAID' ? this.labels.payAction : invoice.status.label;
        case 'invoiceMonth':
          return new Date(invoice.invoiceDate).getTime();
        case 'issueDateFormatted':
          return new Date(invoice.issueDate).getTime();
        case 'dueDateFormatted':
          return new Date(invoice.dueDate).getTime();
        default:
          return invoice[event.id];
      }
    });
    this.invoices = event.direction === 'descendant' ? sortedInvoices.reverse() : sortedInvoices;
  }

  handleFilterChange(event): void {
    this.invoices = _.filter(this.initialInvoices, (invoice) => {
      return invoice.consumptionPointIds.includes(event.id) || event.id === 'all';
    });

    const chartValues = Utils.setChartValues(
      this.invoices,
      this.labels.chart.firstLegendLabel,
      this.forecastInvoices,
      this.hasAggregatedInvoices,
      true,
      this.labels.chart.firstLegendLabel,
    );

    this.barChartData = chartValues.barChartData;
    this.barChartLabels = chartValues.barChartLabels;
  }

  payInvoice(id, type): void {
    document.body.classList.add('no-scroll');

    this.loader.setLoaderState({ loading: true, type: 'backdrop' });
    this.payAction({ invoiceId: id, type: 'single', commodityType: type }).subscribe();
    this.sendGoogleEvent();
  }

  handleCsvExport(): void {
    this.modal = this.modalService.show(
      ExportModalComponent,
      {
        closeOnBackdropClick: false,
      },
      {
        title: this.labels.exportCSV.title,
        subtitle: this.labels.exportCSV.subtitle,
        btnLabel: this.labels.exportCSV.btnLabel,
        columns: this.columns,
        list: this.invoices,
      },
    );
  }

  sendGoogleEvent(): void {
    this.googleAnalyticsService.eventEmitter(
      'Pay invoice',
      'custom_events',
      'pay_invoice',
      'invoices_table_pay_button_clicked',
      1,
    );
  }
}
