<div *ngIf="reducedData$ | async as data" class="collaboration-page mt-32">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-24">
    <div fxLayout="row" fxLayoutAlign="start center">
      <h1 class="page-title mr-48">{{ data.labels.title | flxLocalize }}</h1>
    </div>
  </div>

  <div *ngIf="data.notifications && data.notifications.length" class="notifications-content mt-16">
    <omc-notification-details
      *ngFor="let notification of data.notifications"
      [notification]="notification"
      [labels]="data.labels"
    ></omc-notification-details>
  </div>
  <div *ngIf="data.notifications.length === 0" fxLayout="column" fxLayoutAlign="center center" class="mt-96">
    <img src="../assets/images/empty-state-tickets.svg" alt="empty state image" class="empty-state-image" />
    <p>{{ data.labels.emptyStateNotifications }}</p>
  </div>
</div>
