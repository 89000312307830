import { Component, Input, ViewChild } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import * as copywrite from '../../../../assets/global.json';
import { CustomTooltipService } from '@app/shared/chart-directive/custom-tooltip.service';
import { BaseChartDirective } from '@app/shared/chart-directive/base-chart.directive';

@Component({
  selector: 'omc-consumption-value-bar-chart',
  templateUrl: './consumption-values-bar-chart.component.html',
})
export class ConsumptionValueBarChartComponent {
  @ViewChild(BaseChartDirective) private _chart;
  @Input() data: ChartDataSets[];
  @Input() labels: Array<string>;
  @Input() options: any = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          id: 'history',
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      yAlign: 'bottom',
      xAlign: 'center',
      custom: (tooltipModel) => {
        CustomTooltipService.createCustomTooltip(tooltipModel, this._chart);
      },

      callbacks: {
        title(tooltipItem, data): any {
          const tooltip = tooltipItem[0];
          return data.datasets[tooltip.datasetIndex].title[tooltip.index] || '';
        },

        label(tooltipItem, data): any {
          const consumption = data.datasets[tooltipItem.datasetIndex].consumption[tooltipItem.index] || '';
          if (!consumption) {
            return;
          }

          return [
            {
              label: copywrite.charts.scale.afterLabel.first,
              value: consumption,
            },
          ];
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };
  barChartPlugins = [];
}
