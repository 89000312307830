import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MODAL_DATA, ModalService } from 'paperflow-web-components';
import { Notification } from '@app/main/notifications/interfaces';

@Component({
  selector: 'omc-notification-details-modal',
  templateUrl: './notification-details-modal.component.html',
  styleUrls: ['./notification-details-modal.component.scss'],
})
export class NotificationDetailsModalComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MODAL_DATA) public data: { notification: Notification; title: string },
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    document.body.classList.add('no-scroll');
  }

  closeModal(): void {
    this.modalService.hide();
  }

  ngOnDestroy(): void {
    document.body.classList.remove('no-scroll');
  }
}
