import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'omc-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.scss'],
})
export class PrivacyModalComponent {
  @Output() privacyClosed = new EventEmitter<any>();

  closePrivacy(): void {
    document.body.classList.remove('no-scroll');
    this.privacyClosed.emit();
  }
}
