<div *ngIf="modalData$ | async as data" class="add-meter-reading-modal">
  <ppf-card
    *ngIf="data.collaborationAddReadingInformation && data.collaborationAddReadingInformation.indexReading"
    class="pl-64 pr-64"
  >
    <div fxLayout="row" fxLayoutAlign="end center" class="title">
      <button class="modal-close" (click)="closeModal(data)">
        <ppf-icon name="close"></ppf-icon>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <img
        [src]="'../assets/images/' + data.collaborationAddReadingInformation.indexReading.image + '.svg'"
        alt="Welcome Image"
        class="modal-header-image"
      />
    </div>
    <div>
      <h2 class="modal-title mt-24 mb-8 text-center">
        {{ data.collaborationAddReadingInformation.indexReading.title }}
      </h2>
      <div
        *ngIf="
          data.collaborationAddReadingInformation.indexReading.indexValues &&
          data.collaborationAddReadingInformation.indexReading.indexValues.length
        "
        class="consumption-points-container"
      >
        <div [formGroup]="addMeterForm" class="modal-form pt-16">
          <div
            *ngFor="let field of data.collaborationAddReadingInformation.indexReading.indexValues"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="mb-32 consumption-point-row"
          >
            <div fxFlex="50" class="control-container mt-20 pr-16">
              <ppf-input [label]="texts.firstInputLabel">
                <input [value]="field.consumptionPointName" disabled ppfInput />
              </ppf-input>
            </div>
            <div fxLayout="column" fxFlex="25" class="control-container pr-8">
              <div fxLayout="row" class="field-label-row mb-4">
                <span class="field-label mr-8">{{
                  data.collaborationAddReadingInformation.indexReading.labels.previousIndexLabel
                }}</span>
                <ppf-icon
                  name="question"
                  [ppfTooltip]="data.collaborationAddReadingInformation.indexReading.previousReadingTooltip.message"
                ></ppf-icon>
              </div>
              <ppf-input fxFlexFill>
                <input type="number" [value]="field.previousIndexValue" disabled ppfInput />
              </ppf-input>
            </div>
            <div fxLayout="column" fxFlex="25" class="control-container pl-8">
              <div fxLayout="row" class="field-label-row mb-4">
                <span class="field-label mr-8">{{
                  data.collaborationAddReadingInformation.indexReading.labels.currentIndexLabel
                }}</span>
                <ppf-icon
                  name="question"
                  [ppfTooltip]="data.collaborationAddReadingInformation.indexReading.currentReadingTooltip.message"
                ></ppf-icon>
              </div>
              <ppf-input
                fxFlexFill
                [ngClass]="{ invalid: showErrors(addMeterForm.controls[field.consumptionPointId]) }"
              >
                <input
                  [id]="field.consumptionPointId"
                  [formControlName]="field.consumptionPointId.toString()"
                  [value]="field.currentIndexValue || ''"
                  ppfInput
                />
              </ppf-input>
              <div *ngIf="showErrors(addMeterForm.controls[field.consumptionPointId])" class="form-errors">
                <ppf-hint
                  type="error"
                  [message]="
                    data.collaborationAddReadingInformation.indexReading.errors[
                      showErrors(addMeterForm.controls[field.consumptionPointId])
                    ]
                  "
                ></ppf-hint>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" class="info-container pl-16 mt-32">
        <ppf-icon name="info" class="info-icon mr-12"></ppf-icon>
        {{ data.collaborationAddReadingInformation.indexReading.tooltip.message }}
      </div>
      <div *ngIf="data.actionsFn.save_index_reading" fxLayout="row" fxLayoutAlign="center center">
        <ppf-button
          [title]="getActionLabel(data.collaborationAddReadingInformation)"
          class="mt-32"
          type="fill"
          (onClick)="doAction(data)"
        ></ppf-button>
      </div>
    </div>
  </ppf-card>
</div>
