<div class="ticket-body-wrapper">
  <ppf-card fxLayout="column" class="p-0">
    <ng-content></ng-content>
    <omc-ticket-body
      [id]="ticket.ticketId"
      [status]="ticket.status.value"
      [label]="ticket.status.label"
      [imgSrc]="'../assets/images/tickets/status' + ticket.status.value + '.svg'"
      [title]="ticket.issueDetails.title"
      [message]="ticket.issueDetails.message"
      [date]="ticket.issueDetails.date"
      (openModal)="openModal()"
      [showAllText]="showAllText"
      [showButton]="showButton"
    ></omc-ticket-body>
    <omc-ticket-body
      *ngIf="ticket.status.value === 'CLOSED' || ticket.status.value === 'CANCELLED'"
      [id]="ticket.ticketId"
      [status]="ticket.status.value"
      [label]="ticket.status.label"
      [imgSrc]="'../assets/images/tickets/response.svg'"
      [title]="ticket.closingDetails.title"
      [message]="ticket.closingDetails.message"
      [date]="ticket.closingDetails.date"
      [showButton]="false"
      [showStatus]="false"
      [showAllText]="showAllText"
      class="ticket-response"
    ></omc-ticket-body>
  </ppf-card>
</div>
