import { Component, Inject, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { Utils } from '@app/shared/services/utils';

@Component({
  selector: 'omc-flx-viewport-notifications',
  templateUrl: './flx-viewport-notifications.component.html',
})
export class FlxViewportNotificationsComponent implements OnInit {
  public processName;
  public processStartData;

  constructor(
    @Inject('BASE_API_URL') public baseApiUrl: string,
    @Inject('PROCESS_API_PATH') public processApiPath: string,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(map(() => window.history.state)).subscribe((state) => {
      this.processName = 'collaboration_notifications';
      this.processStartData = Utils.getModuleStartData(state, this.authService.getUserGuid());
    });
  }
}
