<div class="invoices-bar-chart-wrapper">
  <div *ngIf="chartLoaded" class="legend-wrapper">
    <div *ngIf="_chart?.chart?.legend?.legendItems" class="legend" fxLayout="row">
      <div
        *ngFor="let item of _chart.chart.legend.legendItems; let i = index"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="mr-8"
      >
        <span class="legend-icon mr-4" [ngStyle]="{ 'background-color': item.fillStyle }"></span>
        <span>{{ item.text | flxLocalize }} </span>
      </div>
    </div>
  </div>

  <canvas
    baseChart
    [datasets]="data"
    [labels]="labels"
    [options]="options"
    [plugins]="barChartPlugins"
    [legend]="true"
    chartType="bar"
  >
  </canvas>
</div>
