<div [style]="'overflow-y: scroll; height: ' + height">
  <table mat-table [dataSource]="tableData">
    <ng-template #text let-value="cellValue">
      {{ value }}
    </ng-template>

    <ng-container *ngFor="let column of localColumns; let i = index" [cdkColumnDef]="column.id">
      <th cdk-header-cell *cdkHeaderCellDef>
        <div class="header-item" fxLayout="row">
          <span class="column-label mr-8">{{ column.label | flxLocalize }}</span>
          <div
            *ngIf="!notSortableColumnsIds.includes(column.id)"
            (click)="sort(column.id, column.direction)"
            class="cursor-pointer"
          >
            <ppf-icon
              name="sort-arrow-up"
              class="mb-4"
              [ngClass]="{ selected: column.selected && column.direction === 'ascendant' }"
            ></ppf-icon>
            <ppf-icon
              name="sort-arrow-down"
              [ngClass]="{ selected: column.selected && column.direction === 'descendant' }"
            ></ppf-icon>
          </div>
        </div>
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container
          *ngTemplateOutlet="
            templates[column.id] || text;
            context: { rowValue: element, cellValue: element[column.id] }
          "
        ></ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *cdkHeaderRowDef="columnsIds; sticky: length > 2"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: columnsIds" (click)="handleRowClick(row)"></tr>
  </table>
</div>
