<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="center center">
    <span class="title mr-16">{{ data.invoice.invoiceId }}</span>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    {{ data.labels.pagesLabel }}

    <input name="page" class="page-input" type="number" [(ngModel)]="currentPage" />
    <span>/{{ numberOfPages ? numberOfPages : '1' }}</span>
    <ppf-button
      [disabled]="currentPage === 1"
      (onClick)="currentPage = currentPage - 1"
      class="header-button ml-8 mr-8"
      type="flat"
      leftIconName="arrowLeft"
    >
    </ppf-button>
    <ppf-button
      [disabled]="currentPage === numberOfPages"
      (onClick)="currentPage = currentPage + 1"
      class="header-button"
      type="flat"
      leftIconName="arrowRight"
    >
    </ppf-button>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <ppf-button
      *ngIf="docSrc$ && data.invoice && data.invoice.status.value === 'PAID'"
      class="header-button"
      (click)="downloadPdf()"
      type="flat"
      leftIconName="download"
    ></ppf-button>
    <ppf-button
      class="header-button close-button ml-8"
      (click)="closeModal()"
      type="flat"
      leftIconName="close"
    ></ppf-button>
  </div>
</div>
<div *ngIf="docSrc$ | async as docSrc; else loading" fxLayout="row" fxLayoutAlign="center start" class="mt-24">
  <pdf-viewer
    *ngIf="docSrc"
    [src]="docSrc"
    [(page)]="currentPage"
    [show-all]="true"
    [fit-to-page]="true"
    (after-load-complete)="afterLoadComplete($event)"
  ></pdf-viewer>
  <ppf-card *ngIf="data.invoice" fxLayout="column" class="invoice-details ml-16">
    <h2 *ngIf="data.labels">{{ data.labels.invoiceDetailsTitle }}</h2>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="center center">
        <h1 class="invoice-id mr-8">{{ data.invoice.invoiceId }}</h1>
        <span [ngClass]="'status status' + data.invoice.status.value">{{ data.invoice.status.label }}</span>
      </div>
      <img [src]="'../assets/images/invoice-details.svg'" alt="invoice details icon" class="invoice-details-image" />
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <ppf-icon name="calendar" class="mr-8"></ppf-icon>
      <span>{{ invoiceMonth }}</span>
    </div>
    <div class="mt-8">
      <div fxLayout="row">
        <ppf-icon name="money" class="mr-8"></ppf-icon>
        <span class="grey" *ngIf="data.invoice.remainingSum">
          <span class="text-bold">{{data.labels.totalAmountLabel | flxLocalize}}:&nbsp;{{ data.invoice.totalAmount}}&nbsp;</span>
          {{ data.invoice.amount.currency }}
        </span>
        <span *ngIf="!data.invoice.totalAmount">
          <span class="text-bold">{{ data.invoice.amount.value + ' ' }}</span>
          {{ data.invoice.amount.currency }}
        </span>
      </div>
      <div *ngIf="data.invoice.remainingSum" class="pl-32">
        <div fxLayout="row" class="mt-6">
        <span class="text-bold">
            {{data.labels.remainingSumLabel | flxLocalize}}:&nbsp;{{data.invoice.remainingSum}}&nbsp;
        </span>
          {{ data.invoice.amount.currency }}
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="mt-24">
      <ppf-button
        *ngIf="data.invoice.payable && payAction"
        [title]="data.labels.payAction | flxLocalize"
        type="fill"
        (onClick)="doAction()"
        class="mr-24"
      ></ppf-button>

      <ppf-button
        *ngIf="!data.invoice.payable"
        [disabled]="true"
        [title]="data.labels.payAction | flxLocalize"
        type="fill"
        [ppfTooltip]="getTooltipLabel(data.invoice.status.value) | flxLocalize"
        [tooltipPosition]="'top'"
        class="mr-16"
      ></ppf-button>

      <ppf-button
        *ngIf="docSrc"
        [title]="data.labels.downloadAction | flxLocalize"
        type="border"
        (onClick)="downloadPdf()"
      ></ppf-button>
    </div>
  </ppf-card>
</div>

<ng-template #loading>
  <ppf-spinner
    backgroundColor="#ffffff"
    borderColor="transparent"
    visualType="custom"
    size="custom"
    [radius]="90"
    class="full-page-loader"
  ></ppf-spinner>
</ng-template>
