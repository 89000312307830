import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import moment from 'moment';
import _ from 'lodash';

import { Column } from '@app/main/omv-table/table.dictionary';
import { ConsumptionValue, ConsumptionValuesInput } from './interfaces';
import { ExportModalComponent } from '@app/main/export-modal/export-modal.component';
import { ModalService } from 'paperflow-web-components';
import { Utils } from '@app/shared/services/utils';
import { AppEventsService } from '@app/shared/services/app-events.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';

@Component({
  selector: 'omc-consumption-points',
  templateUrl: './consumption-points.component.html',
  styleUrls: ['./consumption-points.component.scss'],
})
export class ConsumptionPointsComponent implements OnInit, OnChanges {
  @Input() data: ConsumptionValuesInput;
  public consumptionValues;
  public initialConsumptionValues: Array<ConsumptionValue>;
  public consumptionPoints: Array<{ id: number; label: string; value?: number }>;
  public labels: any;
  public columns: Array<Column> = [];
  public filterItems: Array<any>;
  public selectedMonth = '';

  public barChartLabels: Array<any> = [];
  public barChartData: Array<any> = [];

  public pieChartLabels: Array<any> = [];
  public pieChartData: Array<any> = [];
  modal: any;

  constructor(
    private appEventsService: AppEventsService,
    private modalService: ModalService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {}

  ngOnInit(): void {
    this.googleAnalyticsService.eventEmitter(
      'Consumption history',
      'in_app_page_view',
      'consumption_history_screen',
      'view_loaded',
      1,
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      if (!this.labels) {
        this.labels = changes.data.currentValue.labels;
        this.columns = [
          { label: this.labels.columns.invoiceMonth, id: 'invoiceMonth', direction: 'ascendant', selected: false },
          { label: this.labels.columns.label, id: 'label', direction: 'ascendant', selected: false },
          { label: this.labels.columns.consumption, id: 'consumption', direction: 'ascendant', selected: false },
          { label: this.labels.columns.type, id: 'type', direction: 'ascendant', selected: false },
        ];
      }

      this.consumptionPoints = changes.data.currentValue.consumptionPoints || [];

      this.setConsumptionValues(changes.data.currentValue);

      const chartValues = Utils.setChartValues(
        changes.data.currentValue.readings || [],
        changes.data.currentValue.labels ? changes.data.currentValue.labels.chart.firstLegendLabel : '',
        null,
        false,
      );

      this.barChartLabels = chartValues.barChartLabels;
      this.barChartData = chartValues.barChartData;
    }
  }

  setConsumptionValues(data): void {
    this.initialConsumptionValues = data.readings?.map((cv) => {
      const consumptionValueLabel = data.consumptionPoints.filter((cp) => cp.id === cv.consumptionPointId);
      cv.label =
        consumptionValueLabel && consumptionValueLabel[0] && consumptionValueLabel[0].label
          ? consumptionValueLabel[0].label
          : '';
      return cv;
    });

    this.consumptionValues = this.initialConsumptionValues;

    if (this.initialConsumptionValues && this.initialConsumptionValues.length) {
      const pieChartConsumptionValue = this.initialConsumptionValues[0];
      this.selectedMonth = pieChartConsumptionValue.invoiceMonth;
      this.setPieChartConsumptionValues(this.initialConsumptionValues, pieChartConsumptionValue, data.labels);
    }

    this.filterItems = [
      { id: 'all', label: data.labels.showAllFilterLabel },
      ...this.consumptionPoints.map((cp) => {
        cp.value = cp.id;
        return cp;
      }),
    ];
  }

  setPieChartConsumptionValues(consumptionValues, selectedConsumptionValue, labels): void {
    let total = 0;
    if (consumptionValues.length) {
      const sortedByConsumptionPointValues = this.consumptionPoints.map((cp) => {
        let cpTotal = 0;
        let label = '';
        consumptionValues.forEach((cv) => {
          if (
            cp.id === cv.consumptionPointId &&
            moment(cv.invoiceDate).isSame(moment(selectedConsumptionValue.invoiceDate), 'month')
          ) {
            total = total + cv.consumption.value;
            cpTotal = cpTotal + cv.consumption.value;
            label = cv.label;
          }
        });
        return { label, consumption: { value: cpTotal } };
      });

      this.setPieChartData(sortedByConsumptionPointValues, total, labels);
    }
  }

  setPieChartData(consumptionValues, total, labels): void {
    // lot of spaces for legend styling
    this.pieChartLabels = consumptionValues.map(
      (cv) => cv.label + ' - ' + ((100 * cv.consumption.value) / total).toFixed(1) + '%',
    );
    this.pieChartData = [
      {
        data: consumptionValues.map((cv) => {
          return cv.consumption.value;
        }),
        total,
        title: consumptionValues.map((cv) => cv.label),
        label: labels.chart.firstLegendLabel,
        xAxisID: 'history',
        backgroundColor: [
          '#4f6f9b',
          '#7994b8',
          '#acbed6',
          '#e8eef4',
          '#5c7aa4',
          '#bfcde0',
          '#6a87ae',
          '#d3ddea',
          '#89a1c2',
        ],
      },
    ];
  }

  sort(event): void {
    const sortedConsumptionValues = _.sortBy(this.consumptionValues, (cv) => {
      switch (event.id) {
        case 'type':
          return cv.type.label;
        case 'consumption':
          return cv.consumption.value;
        case 'invoiceMonth':
          return new Date(cv.invoiceDate).getTime();
        default:
          return cv[event.id];
      }
    });
    this.consumptionValues =
      event.direction === 'descendant' ? sortedConsumptionValues.reverse() : sortedConsumptionValues;
  }

  handleFilterChange(event): void {
    this.consumptionValues = _.filter(this.initialConsumptionValues, (cv) => {
      return cv.consumptionPointId === event.id || event.id === 'all';
    });

    const chartValues = Utils.setChartValues(this.consumptionValues, this.labels.chart.firstLegendLabel, [], false);

    this.barChartLabels = chartValues.barChartLabels;
    this.barChartData = chartValues.barChartData;

    this.googleAnalyticsService.eventEmitter(
      'Filter consumption points',
      'custom_events',
      'filter_consumption_history',
      'click',
      1,
    );
  }

  handleCsvExport(): void {
    this.modal = this.modalService.show(
      ExportModalComponent,
      {
        closeOnBackdropClick: false,
      },
      {
        title: this.labels.exportCSV.title,
        subtitle: this.labels.exportCSV.subtitle,
        btnLabel: this.labels.exportCSV.btnLabel,
        columns: this.columns,
        list: this.consumptionValues,
      },
    );
  }
}
