<div *ngIf="accountInfo && reducedData$ | async as data" class="collaboration-page">
  <div fxLayout="row" fxLayoutAlign="start center" class="mt-24">
    <h1 class="page-title mr-48 mb-16">{{ data.labels.title | flxLocalize }}</h1>
  </div>
  <div fxLayout="row">
    <ppf-card fxLayout="column" fxFlex="66" class="bar-chart-card p-24 mr-24 mt-0">
      <div *ngIf="barChartData && barChartData[0] && barChartData[0].data.length > 0; else emptyState">
        <h2 class="headline mb-24">{{ data.labels.invoicesChart | flxLocalize }}</h2>
        <omc-invoices-bar-chart [data]="barChartData" [labels]="barChartLabels"></omc-invoices-bar-chart>
      </div>
      <ng-template #emptyState>
        <div fxLayout="column" fxLayoutAlign="center center" fxFill>
          <img src="../assets/images/empty-state.svg" alt="empty state image" class="empty-state-image" />
          <p>{{ data.labels.emptyStateInvoices | flxLocalize }}</p>
        </div>
      </ng-template>
    </ppf-card>
    <ppf-card *ngIf="data.invoice" fxLayout="column" fxFlex="33" fxLayoutAlign="space-between center" class="p-24">
      <div>
        <h2 class="headline">{{ data.labels.lastInvoice | flxLocalize }}</h2>
        <omc-dashboard-invoice
          [invoice]="data.invoice.gas"
          [labels]="data.labels"
          [payAction]="data.actionsFn.open_payment_modal"
          type="gas"
        ></omc-dashboard-invoice>
        <omc-dashboard-invoice
          [invoice]="data.invoice.power"
          [labels]="data.labels"
          [payAction]="data.actionsFn.open_payment_modal"
          type="power"
        ></omc-dashboard-invoice>
      </div>
      <div
        *ngIf="(!data.invoice.gas && data.invoice.power) || (data.invoice.gas && !data.invoice.power)"
        fxLayout="row"
        fxLayoutAlign="center center"
        class="mb-16"
      >
        <ppf-button
          [title]="data.labels.openInvoices"
          type="flat"
          (onClick)="redirectToInvoices(!!data.invoice.gas)"
        ></ppf-button>
      </div>
      <div
        *ngIf="!data.invoice.gas && !data.invoice.power"
        fxLayout="column"
        fxLayoutAlign="center center"
        class="mt-48 text-center"
      >
        <img src="../assets/images/empty-state.svg" alt="empty state image" class="last-invoice-empty-state-image" />
        <span class="mt-16 text-bold empty-state-text">{{ data.labels.emptyStateLastInvoiceTitle | flxLocalize }}</span>
        <span class="mt-16 empty-state-text">{{ data.labels.emptyStateLastInvoiceSubTitle | flxLocalize }}</span>
      </div>
    </ppf-card>
  </div>
  <div fxLayout="row" class="mt-16">
    <div fxFlex="33" class="mr-16">
      <omc-dashboard-offer
        *ngFor="let offer of data.offer"
        [offer]="offer.offer"
        [contract]="offer.contract"
        [type]="offer.type"
        [actions]="data.actionsFn"
      ></omc-dashboard-offer>
    </div>
    <omc-tickets
      *ngIf="data.labels && data.actionsFn"
      fxFlex="66"
      [title]="data.labels.ticketsTitle | flxLocalize"
      [tickets]="sortTickets(data.tickets)"
      [openModal]="getTicketsAction(data)"
      [buttonLabel]="getTicketsActionLabel(data) | flxLocalize"
      [emptyStateLabel]="data.labels.emptyStateTickets | flxLocalize"
      [modalLabel]="data.labels.ticketDetailsTitle | flxLocalize"
      googleAction="support_empty_state_button_clicked"
    ></omc-tickets>
  </div>
</div>
