<div *ngIf="accountInfo && reducedData$ | async as data" class="collaboration-page">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-24">
    <div fxLayout="row" fxLayoutAlign="start center">
      <h1 class="page-title mr-32">{{ data.labels.title | flxLocalize }}</h1>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="tab-button" (click)="selectedIndex = 0; sendChangedTabGoogleEvent(0)">
          <span [ngClass]="{ selected: selectedIndex === 0 }" class="tab-label">
            {{ data.labels.firstTabLabel | flxLocalize }}
          </span>
        </div>
        <div class="tab-button" (click)="selectedIndex = 1; sendChangedTabGoogleEvent(0)">
          <span [ngClass]="{ selected: selectedIndex === 1 }" class="tab-label">
            {{ data.labels.secondTabLabel | flxLocalize }}
          </span>
        </div>
        <div class="tab-button" (click)="selectedIndex = 2; sendChangedTabGoogleEvent(0)">
          <span [ngClass]="{ selected: selectedIndex === 2 }" class="tab-label">
            {{ data.labels.thirdTabLabel | flxLocalize }}
          </span>
        </div>
      </div>
    </div>
    <div fxLayout="row" class="support-actions">
      <ppf-button
        *ngIf="data.callSupport"
        [title]="data.callSupport.phoneNumber"
        type="flat"
        leftIconName="phone"
        class="call-action"
      ></ppf-button>
      <ppf-button
        *ngIf="getAction(data)"
        type="fill"
        leftIconName="add"
        [title]="getActionLabel(data) | flxLocalize"
        (onClick)="addTicket(data)"
      >
      </ppf-button>
    </div>
  </div>
  <div *ngIf="selectedIndex === 0">
    <omc-tickets
      [tickets]="tickets"
      [openModal]="getAction(data)"
      [buttonLabel]="getActionLabel(data) | flxLocalize"
      [emptyStateLabel]="data.labels.emptyStateTickets | flxLocalize"
      [modalLabel]="data.labels.detailsTitle | flxLocalize"
      googleAction="support_empty_state_button_clicked"
    ></omc-tickets>
  </div>
  <div *ngIf="selectedIndex === 1">
    <omc-tickets
      [tickets]="openTickets"
      [openModal]="getAction(data)"
      [buttonLabel]="getActionLabel(data) | flxLocalize"
      [emptyStateLabel]="data.labels.emptyStateTickets | flxLocalize"
      [modalLabel]="data.labels.detailsTitle | flxLocalize"
      googleAction="support_empty_state_button_clicked"
    ></omc-tickets>
  </div>
  <div *ngIf="selectedIndex === 2">
    <omc-tickets
      [tickets]="closedTickets"
      [openModal]="getAction(data)"
      [buttonLabel]="getActionLabel(data) | flxLocalize"
      [emptyStateLabel]="data.labels.emptyStateTickets | flxLocalize"
      [modalLabel]="data.labels.detailsTitle | flxLocalize"
      googleAction="support_empty_state_button_clicked"
    ></omc-tickets>
  </div>
</div>
