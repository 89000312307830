import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SidebarUserInfo } from '@app/main/menu/dictionary';
import { NotificationInput } from '@app/main/notifications/interfaces';
import { AppEventsService } from '@app/shared/services/app-events.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';

@Component({
  selector: 'omc-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @Input() data$: Observable<NotificationInput>;
  public reducedData$: Observable<any>;

  private appEvent$ = this.appEventsService.appEvent$;
  public accountInfo: SidebarUserInfo;
  public data: any;

  constructor(private appEventsService: AppEventsService, private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.googleAnalyticsService.eventEmitter(
      'Notifications',
      'in_app_page_view',
      'notifications_screen',
      'view_loaded',
      1,
    );

    this.reducedData$ = this.appEventsService.userEventsInit(
      this.data$,
      'notifications',
      'collaborationNotificationsInformation',
    );

    this.appEvent$.subscribe((data) => {
      this.accountInfo = data.accountInfo;
    });
  }

  ngOnDestroy(): void {
    this.appEventsService.unsubscribeUserEvents();
  }
}
