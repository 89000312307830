import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from '@app/core/core.module';
import { StoreModule } from '@ngrx/store';

import { environment } from '../environments/environment';

import {
  calendar,
  close,
  error,
  IconModule,
  info,
  playVertical,
  success,
  warning,
  menu,
} from 'paperflow-web-components';
import {
  arrowLeft,
  arrowRight,
  arrowDown,
  magnifier,
  question,
  menuAccount,
  menuConsumption,
  menuDashboard,
  menuInvoice,
  menuPower,
  menuGas,
  menuNews,
  menuReports,
  menuServices,
  menuSupport,
  sortArrowUp,
  sortArrowDown,
  money,
  add,
  exportFile,
  download,
  logout,
  notification,
  phone,
  buttonArrowRight,
  buttonArrowLeft,
  documentCheck,
} from './shared/constants/icons';
import { ErrorInterceptor } from '@app/core/auth/error.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    StoreModule.forRoot({}, { runtimeChecks: { strictStateImmutability: true } }),
    HttpClientModule,
    IconModule.forRoot({
      icons: [
        info,
        error,
        success,
        warning,
        close,
        arrowLeft,
        arrowRight,
        arrowDown,
        magnifier,
        calendar,
        question,
        playVertical,
        menu,
        menuServices,
        menuSupport,
        menuReports,
        menuNews,
        menuInvoice,
        menuDashboard,
        menuGas,
        menuPower,
        menuConsumption,
        menuAccount,
        sortArrowUp,
        sortArrowDown,
        money,
        add,
        exportFile,
        download,
        logout,
        notification,
        phone,
        buttonArrowRight,
        buttonArrowLeft,
        documentCheck,
      ],
    }),
  ],
  providers: [
    HttpClient,
    { provide: 'BASE_API_URL', useValue: environment.baseUrl },
    { provide: 'PROCESS_API_PATH', useValue: environment.processApiPath },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
