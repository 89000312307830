import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from './base-chart.directive';

@NgModule({
  declarations: [BaseChartDirective],
  imports: [CommonModule],
  exports: [BaseChartDirective],
})

// imported chart directive manually to fix crashed tests
export class ChartModule {}
