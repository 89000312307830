import { Component, Input } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'omc-consumption-value-pie-chart',
  templateUrl: './consumption-values-pie-chart.component.html',
})
export class ConsumptionValuePieChartComponent {
  @Input() data: ChartDataSets[];
  @Input() labels: Array<string>;
  @Input() options: any = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      align: 'center',
      position: 'bottom',
      labels: {
        boxWidth: 12,
        padding: 20,
        fontStyle: 'bold',
      },
      onClick: null,
    },
    tooltips: {
      enabled: false,
    },
    elements: {
      arc: {
        borderWidth: 1,
      },
    },
    plugins: {
      datalabels: {
        textShadowColor: '#000',
        textShadowBlur: 2,
        align: 'start',
        anchor: 'end',
        color: '#ffffff',
        font: {
          weight: 'bold',
          size: 12,
        },
        formatter: (value, context) => {
          return ((100 * value) / context.dataset.total).toFixed(1) + '%';
        },
      },
    },
  };
  pieChartPlugins = [ChartDataLabels];
}
