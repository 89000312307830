import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import { Observable } from 'rxjs';

import { AppEventsService } from '@app/shared/services/app-events.service';
import { SidebarUserInfo } from '@app/main/menu/dictionary';
import { Ticket } from '@app/main/tickets/interfaces';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';

@Component({
  selector: 'omc-tickets-main',
  templateUrl: './tickets-main.component.html',
  styleUrls: ['./tickets-main.component.scss'],
})
export class TicketsMainComponent implements OnInit, OnDestroy {
  @Input() data$: Observable<any>;
  public reducedData$: Observable<any>;
  private appEvent$ = this.appEventsService.appEvent$;
  public accountInfo: SidebarUserInfo;
  public data: any;
  public tickets: Array<Ticket>;
  public allTickets: Array<Ticket>;
  public openTickets: Array<Ticket>;
  public closedTickets: Array<Ticket>;
  public addTicketDelay = true;

  public selectedIndex = 0;

  constructor(private appEventsService: AppEventsService, private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.googleAnalyticsService.eventEmitter(
      'Support - all tickets',
      'in_app_page_view',
      'support_all_tickets_screen',
      'view_loaded',
      1,
    );

    this.reducedData$ = this.appEventsService.userEventsInit(this.data$, 'support', 'collaborationSupportInformation');

    this.appEvent$.subscribe((data) => {
      this.accountInfo = data.accountInfo;
    });

    this.reducedData$.subscribe((data) => {
      if (data && data.tickets) {
        if (this.tickets && this.tickets.length >= 0 && data.tickets.length > this.tickets.length) {
          this.removeDashboardProcess();
        }

        this.tickets = data.tickets;

        this.openTickets = _.filter(this.tickets, (ticket) => ticket.status.value === 'OPEN');
        this.closedTickets = _.filter(
          this.tickets,
          (ticket) => ticket.status.value === 'CLOSED' || ticket.status.value === 'CANCELLED',
        );
      }
    });
  }

  addTicket(data): void {
    if (this.addTicketDelay) {
      this.getAction(data)().subscribe();
      this.sendAddTicketGoogleEvent();

      this.addTicketDelay = false;

      setTimeout(() => {
        this.addTicketDelay = true;
      }, 500);
    }
  }

  getAction(data): any {
    return data.actionsFn && data.actionsFn.add_support_ticket ? data.actionsFn.add_support_ticket : null;
  }

  getActionLabel(data): string {
    const createTicketAction = _.filter(data.actionsMetadata, (action) => action.name === 'add_support_ticket');
    return createTicketAction.length ? createTicketAction[0].label : '';
  }

  sendAddTicketGoogleEvent(): void {
    this.googleAnalyticsService.eventEmitter('Add new ticket', 'custom_events', 'support_add', 'top_button_clicked', 1);
  }

  sendChangedTabGoogleEvent(index): void {
    const tabs = ['support_all_tickets_screen', 'support_open_tickets_screen', 'support_closed_tickets_screen'];
    this.googleAnalyticsService.eventEmitter('Add new ticket', 'custom_events', tabs[index], 'top_button_clicked', 1);
  }

  removeDashboardProcess(): void {
    const processes = JSON.parse(localStorage.getItem('processInstances') as any);

    delete processes.collaboration_dashboard;
    localStorage.setItem('processInstances', JSON.stringify(processes));
  }

  ngOnDestroy(): void {
    this.appEventsService.unsubscribeUserEvents();
  }
}
