<div *ngIf="accountInfo && reducedData$ | async as data" class="collaboration-page">
  <div fxLayout="row" fxLayoutAlign="start center" class="mt-24">
    <h1 class="page-title mr-48">{{ data.title | flxLocalize }}</h1>
    <div fxLayout="row">
      <div class="tab-button" (click)="selectedIndex = 0">
        <span [ngClass]="{ selected: selectedIndex === 0 }" class="tab-label">
          {{ labels.firstTabLabel | flxLocalize }}
        </span>
      </div>
      <div class="tab-button" (click)="selectedIndex = 1">
        <span [ngClass]="{ selected: selectedIndex === 1 }" class="tab-label">
          {{ labels.secondTabLabel | flxLocalize }}
        </span>
      </div>
      <div class="tab-button" (click)="selectedIndex = 2">
        <span [ngClass]="{ selected: selectedIndex === 2 }" class="tab-label">
          {{ labels.thirdTabLabel | flxLocalize }}
        </span>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxFill *ngIf="selectedIndex === 0">
    <omc-invoices [data]="invoicesData" [actionsFn]="actionsFn"></omc-invoices>
  </div>
  <div fxLayout="column" fxFill *ngIf="selectedIndex === 1">
    <omc-payments [data]="paymentsData"></omc-payments>
  </div>
  <div fxLayout="column" fxFill *ngIf="selectedIndex === 2">
    <omc-consumption-points [data]="consumptionPointsData"></omc-consumption-points>
  </div>
</div>
