<div *ngIf="invoice" class="dashboard-invoice mt-16">
  <div class="dashboard-info" [ngClass]="{ 'green-background': type === 'gas', 'blue-background': type === 'power' }">
    <div fxLayout="row" fxLayoutAlign="start center" class="mb-16">
      <h2 (click)="openModal()" class="mr-16" [ngClass]="{ green: type === 'gas', blue: type === 'power' }">
        {{ invoice.invoiceId }}
      </h2>
      <div *ngIf="type === 'gas'" class="tag strong-green-background">{{ 'Gaze naturale' }}</div>
      <div *ngIf="type === 'power'" class="tag strong-blue-background">{{ 'Energie' }}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <ppf-icon name="calendar" class="mr-8"></ppf-icon>
      <span>{{ (labels.dueBy | flxLocalize) + invoice.dueDateFormatted }}</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" class="mt-8">
      <ppf-icon name="money" class="mr-8"></ppf-icon>
      <span class="text-bold"> {{ invoice.amount.value }}&nbsp; </span>
      {{ invoice.amount.currency }}
    </div>
  </div>
</div>
