<ppf-card class="dashboard-offer mb-16 p-32" fxLayout="column" fxLayoutAlign="start start">
  <div fxFlexFill *ngIf="offer">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center">
        <img [src]="'../assets/images/house.svg'" alt="Product image" class="image mr-16" />
        <div class="product-title headline" [ngClass]="{ blue: type === 'POWER', green: type === 'GAS' }">
          {{ type === 'GAS' ? 'Utilio GAS' : 'Utilio POWER' }}
        </div>
      </div>
      <ppf-icon
        name="arrowDown"
        class="ml-32 mr-16 cursor-pointer"
        [ngClass]="{ revert: !showContent }"
        (click)="showContent = !showContent"
      ></ppf-icon>
    </div>
    <div *ngIf="showContent" class="offer-fields">
      <div *ngFor="let field of offer.fields" class="field-container pb-16 pt-16">
        <div *ngIf="field?.values?.length" fxLayout="row" fxLayoutAlign="start center" class="pb-4">
          <span class="field-key">{{ field.title }}</span>
        </div>
        <span *ngFor="let value of field.values">
          <span
            [ngClass]="{ 'text-bold': value.type === 'bold', 'display-block': field.title === 'Servicii opționale' }"
            class="field-value"
            [innerHTML]="value.text"
          ></span>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="contract" fxLayout="column" fxLayoutAlign="center center" class="text-center">
    <img src="../assets/images/iconHeaderIndexReading.svg" alt="empty state image" class="empty-state-image mb-16" />
    <span class="text-bold empty-state-text mb-16"> {{ contract.title }}</span>
    <span class="empty-state-text">{{ contract.description }}</span>
    <ppf-button class="mt-32" [title]="contract.buttonTitle" (onClick)="doAction()"></ppf-button>
  </div>
</ppf-card>
