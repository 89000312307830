import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlxProcessModule } from 'flowx-process-renderer';

import { OMV_VALIDATORS } from '@app/shared/validators';
import { LocalDataStoreService } from '@app/core/local-data-store.service';
import { SharedModule } from '@app/shared/shared.module';
import { FlxViewportPowerRoutingModule } from '@app/flx-viewport-power/flx-viewport-power-routing.module';
import { FlxViewportPowerComponent } from '@app/flx-viewport-power/flx-viewport-power.component';
import { PayAllComponent } from '@app/main/pay-all/pay-all.component';
import { AddMeterReadingComponent } from '@app/main/add-meter-reading/add-meter-reading.component';
import { PowerCommodityComponent } from '@app/main/commodity/powerCommodity.component';
import { ErrorHandlingComponent } from '@app/error-handling/error-handling.component';

@NgModule({
  declarations: [FlxViewportPowerComponent],
  imports: [
    CommonModule,
    SharedModule,
    FlxViewportPowerRoutingModule,
    FlxProcessModule.forRoot({
      components: {
        // here will be my custom components
        MarvelWebPowerComponent: PowerCommodityComponent,
        AddMeterReading: AddMeterReadingComponent,
        PayAllModal: PayAllComponent,
        ErrorHandling: ErrorHandlingComponent,
      },
      services: {
        // here I wil load my custom services (Nomenclator, LocalStorage)
        LocalDataStoreService,
      },
      validators: OMV_VALIDATORS,
    }),
  ],
})
export class FlxViewportPowerModule {}
