import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared/shared.module';
import { MainRoutingModule } from './main-routing.module';
import { MenuComponent } from '@app/main/menu/menu.component';
import { AddMeterReadingComponent } from '@app/main/add-meter-reading/add-meter-reading.component';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTableModule } from '@angular/material/table';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OmvTableComponent } from '@app/main/omv-table/table.component';
import { ConsumptionPointsComponent } from '@app/main/consumption-points/consumption-points.component';
import { InvoiceDetailsComponent } from '@app/main/invoice-details/invoice-details.component';
import { InvoicesComponent } from '@app/main/invoices/invoices.component';
import { CommodityComponent } from '@app/main/commodity/commodity.component';
import { PaymentsComponent } from '@app/main/payments/payments.component';
import { InvoicesBarChartComponent } from '@app/main/invoices/invoices-bar-chart/invoices-bar-chart.component';
import { ConsumptionValueBarChartComponent } from '@app/main/consumption-points/consumption-value-bar-chart/consumption-values-bar-chart.component';
import { ConsumptionValuePieChartComponent } from '@app/main/consumption-points/consumption-value-pie-chart/consumption-values-pie-chart.component';
import { PayAllComponent } from '@app/main/pay-all/pay-all.component';
import { ExportModalComponent } from './export-modal/export-modal.component';
import { TicketsMainComponent } from '@app/main/tickets-main/tickets-main.component';
import { TicketsComponent } from '@app/main/tickets/tickets.component';
import { TicketBodyComponent } from '@app/main/ticket-body/ticket-body.component';
import { TicketDetailsComponent } from '@app/main/ticket-details/ticket-details.component';
import { TicketDetailsModalComponent } from '@app/main/ticket-details-modal/ticket-details-modal.component';
import { DashboardComponent } from '@app/main/dashboard/dashboard.component';
import { DashboardInvoiceComponent } from '@app/main/dashboard-invoice/dashboard-invoice.component';
import { DashboardOfferComponent } from '@app/main/dashboard-offer/dashboard-offer.component';
import { AccountMainComponent } from '@app/main/account-main/account-main.component';
import { PersonalInfoComponent } from '@app/main/personal-info/personal-info.component';
import { UserDocumentsComponent } from '@app/main/user-documents/user-documents.component';
import { NotificationSettingsComponent } from '@app/main/notification-settings/notification-settings.component';
import { NotificationsComponent } from '@app/main/notifications/notifications.component';
import { NotificationBodyComponent } from '@app/main/notification-body/notification-body.component';
import { NotificationDetailsModalComponent } from '@app/main/notification-details-modal/notification-details-modal.component';
import { NotificationDetailsComponent } from '@app/main/notification-details/notification-details.component';
import { OmvMenuComponent } from '@app/main/omv-menu/omv-menu.component';
import { PowerCommodityComponent } from '@app/main/commodity/powerCommodity.component';
import { ErrorHandlingComponent } from '@app/error-handling/error-handling.component';

@NgModule({
  declarations: [
    AddMeterReadingComponent,
    ConsumptionPointsComponent,
    InvoiceDetailsComponent,
    InvoicesComponent,
    CommodityComponent,
    PowerCommodityComponent,
    InvoicesBarChartComponent,
    ConsumptionValueBarChartComponent,
    ConsumptionValuePieChartComponent,
    MenuComponent,
    PaymentsComponent,
    OmvTableComponent,
    PayAllComponent,
    ExportModalComponent,
    TicketsMainComponent,
    TicketsComponent,
    TicketBodyComponent,
    TicketDetailsComponent,
    TicketDetailsModalComponent,
    DashboardComponent,
    DashboardInvoiceComponent,
    DashboardOfferComponent,
    AccountMainComponent,
    PersonalInfoComponent,
    UserDocumentsComponent,
    NotificationSettingsComponent,
    NotificationsComponent,
    NotificationBodyComponent,
    NotificationDetailsModalComponent,
    NotificationDetailsComponent,
    OmvMenuComponent,
    ErrorHandlingComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MainRoutingModule,
    CdkTableModule,
    MatTableModule,
    ScrollingModule,
  ],
})
export class MainModule {}
