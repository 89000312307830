import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpErrorStatus } from './dictionary';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly oauthService: OAuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case HttpErrorStatus.UNKNOWN_ERROR:
            console.log('UNKNOWN ERROR OCCURRED', error);
            break;
          case HttpErrorStatus.BAD_REQUEST:
            if (error.error && error.error.error === 'invalid_grant') {
              this.oauthService.logOut();
            }

            console.log('BAD REQUEST OCCURRED', error);
            break;
        }
        return throwError(error);
      }),
    );
  }
}
