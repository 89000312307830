import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private loaderSubject = new BehaviorSubject<{ loading: boolean; type: string }>({ loading: false, type: 'normal' });
  readonly loader$ = this.loaderSubject.asObservable();

  setLoaderState(value: { loading: boolean; type: string }): void {
    this.loaderSubject.next(value);
  }
}
