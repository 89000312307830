import { Component, Input } from '@angular/core';
import { InvoiceDetailsComponent } from '@app/main/invoice-details/invoice-details.component';
import { ModalService } from 'paperflow-web-components';
import { Invoice } from '@app/main/invoices/interfaces';

@Component({
  selector: 'omc-dashboard-invoice',
  templateUrl: './dashboard-invoice.component.html',
  styleUrls: ['./dashboard-invoice.component.scss'],
})
export class DashboardInvoiceComponent {
  @Input() invoice: Invoice;
  @Input() labels: any;
  @Input() payAction: any;
  @Input() type: string;
  modal: any;

  constructor(private modalService: ModalService) {}

  openModal(): void {
    this.modal = this.modalService.show(
      InvoiceDetailsComponent,
      {
        closeOnBackdropClick: false,
      },
      { invoice: this.invoice, payAction: this.payAction, labels: this.labels },
    );
  }
}
