import { Component } from '@angular/core';
import { MenuComponent } from 'paperflow-web-components';

@Component({
  selector: 'omc-dropdown-menu',
  templateUrl: './omv-menu.component.html',
  styleUrls: ['./omv-menu.component.scss'],
})
export class OmvMenuComponent extends MenuComponent {
  trigger(event: Event): boolean {
    this.showMenu = !this.showMenu;
    return this.showMenu;
  }
}
