import { Component, Input } from '@angular/core';
import { Notification } from '@app/main/notifications/interfaces';
import { ModalService } from 'paperflow-web-components';
import { NotificationDetailsModalComponent } from '@app/main/notification-details-modal/notification-details-modal.component';

@Component({
  selector: 'omc-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss'],
})
export class NotificationDetailsComponent {
  @Input() notification: Notification;
  @Input() showButton = true;
  @Input() showAllText = false;
  @Input() labels: any;
  private modal: any;

  constructor(private modalService: ModalService) {}

  openModal(): void {
    this.modal = this.modalService.show(
      NotificationDetailsModalComponent,
      {
        closeOnBackdropClick: false,
      },
      { notification: this.notification, label: this.labels.detailsTitle },
    );
  }
}
