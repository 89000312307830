<div class="home-page full-page">
  <div fxLayout="row">
    <div fxFlex="25" class="menu-section">
      <div *ngIf="accountInfo; else loading">
        <ppf-card class="account-info mt-32" fxLayout="column">
          <div class="account-name" fxLayout="row" fxLayoutAlign="start center">
            <div class="name-logo mr-16">{{ getInitials(accountInfo.name) }}</div>
            <h3 class="name">{{ accountInfo.name }}</h3>
          </div>
          <div fxLayout="row" class="mt-16">
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              [ngClass]="{ active: activeNotifications }"
              class="account-section mr-8"
            >
              <ppf-button type="flat" leftIconName="notification" (click)="goToNotifications()"></ppf-button>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" class="account-section">
              <ppf-button type="flat" leftIconName="logout" (onClick)="logout()"></ppf-button>
            </div>
          </div>
          <div
            *ngFor="let balance of accountInfo.accountBalance"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="account-section account-balance mt-8"
            [ngClass]="{ 'green-background': balance.type === 'GAS', 'blue-background': balance.type === 'POWER' }"
          >
            <div class="balance">
              <div class="text-bold" [ngClass]="{ green: balance.type === 'GAS', blue: balance.type === 'POWER' }">
                {{ (accountInfo.labels.total | flxLocalize) + balance.type }}
              </div>
              <span class="text-slight mr-4">{{ balance.amount.value.toFixed(2) }}</span>
              <span class="currency">{{ balance.amount.currency }}</span>
            </div>
            <ppf-button
              *ngIf="accountInfo.payAction"
              class="pay-button"
              [ngClass]="{ disabled: balance.amount.value <= 0 }"
              [title]="accountInfo.labels.pay | flxLocalize"
              [disabled]="balance.amount.value <= 0"
              (onClick)="payAction(balance.type)"
            ></ppf-button>
          </div>

          <!--<div fxLayout="row" fxLayoutAlign="space-between center" class="account-section index-section mt-8">-->
          <!--<div fxLayout="row" fxLayoutAlign="start center">-->
          <!--<span class="text-bold mr-8">{{ accountInfoCopywrite.add }}</span>-->
          <!--<ppf-icon-->
          <!--class="index-info"-->
          <!--name="question"-->
          <!--[ppfTooltip]="'Introducerea indexului se poate face in ultimele 5 zile calendaristice din luna'"-->
          <!--&gt;-->
          <!--</ppf-icon>-->
          <!--</div>-->
          <!--<ppf-button-->
          <!--class="index-button"-->
          <!--type="flat"-->
          <!--leftIconName="add"-->
          <!--[disabled]="!accountInfo.addMeterReading || !accountInfo.enableIndexReading"-->
          <!--&gt;</ppf-button>-->
          <!--</div>-->
        </ppf-card>
        <ppf-card class="menu-container pt-24 pb-24 pl-16 pr-16 mt-16" fxLayout="column">
          <div
            *ngFor="let menuItem of filteredSidebarLinks"
            fxLayout="row"
            [ngClass]="{
              active: menuItem.route == activeMenuItem,
              green: menuItem.route === 'gas',
              blue: menuItem.route === 'power'
            }"
            (click)="doAItemAction(menuItem)"
            class="menu-item pl-16"
          >
            <ppf-icon [name]="'menu-' + menuItem.icon" class="menu-icon mr-12"></ppf-icon>
            {{ menuItem.label }}
          </div>
        </ppf-card>
      </div>
    </div>
    <div fxFlex="75" class="pl-16">
      <router-outlet></router-outlet>
    </div>
  </div>

  <!--use also template loader, works better on init-->
  <ng-template #loading>
    <ppf-spinner
      visualType="custom"
      size="custom"
      [radius]="90"
      fxLayout="column"
      fxLayoutAlign="center center"
      class="full-page-loader"
    ></ppf-spinner>
  </ng-template>

  <ng-container *ngIf="loaderState$ | async as loaderState">
    <div *ngIf="loaderState.loading" [ngClass]="{ 'full-page-backdrop': loaderState.type === 'backdrop' }">
      <ppf-spinner
        visualType="custom"
        size="custom"
        [radius]="90"
        fxLayout="column"
        fxLayoutAlign="center center"
        class="full-page-loader"
      ></ppf-spinner>
    </div>
  </ng-container>
</div>
