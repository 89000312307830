<div *ngIf="payments" class="payments">
  <div *ngIf="payments.length">
    <ppf-card fxLayout="column" class="payments-card">
      <h2 class="headline">{{ labels.title | flxLocalize }}</h2>
      <div fxLayout="row" class="table-actions" fxLayoutAlign="space-between center">
        <div fxLayout="row" class="link-button export-csv-section">
          <ppf-icon name="download"></ppf-icon>
          <a (click)="handleCsvExport()" class="semibold">{{ labels.exportCSV.title | flxLocalize }}</a>
        </div>
      </div>
      <omc-table
        [data]="payments"
        height="calc(100vh - 284px)"
        [columns]="columns"
        [notSortableColumnsIds]="[]"
        [templates]="{ amount: amountColumnT }"
        (sortClicked)="sort($event)"
      >
        <ng-template #amountColumnT let-data="rowValue" let-value="cellValue">
          <b>{{ data.amount.value }} </b>{{ data.amount.currency }}
        </ng-template>
      </omc-table>
    </ppf-card>
  </div>
  <div *ngIf="payments.length === 0" fxLayout="column" fxLayoutAlign="center center" fxFill>
    <img src="../assets/images/empty-state.svg" alt="empty state image" class="empty-state-image" />
    <p>{{ labels.emptyStatePayments | flxLocalize }}</p>
  </div>
</div>
