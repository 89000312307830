import { Injectable } from '@angular/core';
import { AuthConfig, NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';

@Injectable()
export class AuthService {
  private _decodedAccessToken: any;
  private _decodedIDToken: any;

  get decodedAccessToken(): string {
    return this._decodedAccessToken;
  }

  get decodedIDToken(): string {
    return this._decodedIDToken;
  }

  constructor(private oauthService: OAuthService, private authConfig: AuthConfig) {}

  async initAuth(): Promise<any> {
    return new Promise((resolveFn, rejectFn) => {
      // setup oauthService
      this.oauthService.configure(this.authConfig);
      this.oauthService.setStorage(localStorage);
      this.oauthService.tokenValidationHandler = new NullValidationHandler();

      // subscribe to token events
      this.oauthService.events
        .pipe(
          filter((e: any) => {
            return e.type === 'token_received';
          }),
        )
        .subscribe(() => {
          this.handleNewToken();
        });

      // continue initializing app or redirect to login-page

      this.oauthService.loadDiscoveryDocumentAndLogin().then((isLoggedIn) => {
        if (isLoggedIn) {
          this.oauthService.setupAutomaticSilentRefresh();
          resolveFn();
        } else {
          this.oauthService.initImplicitFlow();
          rejectFn();
        }
      });
    });
  }

  private handleNewToken(): void {
    this._decodedAccessToken = this.oauthService.getAccessToken();
    this._decodedIDToken = this.oauthService.getIdToken();
  }

  getUserGuid(): string {
    const attributes = (jwtDecode(this.oauthService.getAccessToken()) as any).attributes;

    return attributes ? attributes.guid : '';
  }
}
