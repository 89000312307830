<div *ngIf="data">
  <ppf-card *ngFor="let category of data" fxLayout="column" class="mt-16 p-32 account-card documents-card">
    <h3 [ngClass]="{ blue: category.type === 'POWER', green: category.type === 'GAS' }">{{ category.title }}</h3>
    <div class="documents-wrapper mt-16" fxLayout="row wrap" fxLayoutGap="16px">
      <ppf-card
        fxFlex="48"
        *ngFor="let document of category.documents"
        class="document-item mb-8"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div fxLayout="column">
          <ppf-icon name="documentCheck"></ppf-icon>
        </div>

        <div class="document-info pl-12">
          <div class="headline text-bold mb-4">{{ document.name }}</div>
          <a class="link" (click)="downloadPdf(document.documentUrl, document.name)">{{ labels.download | flxLocalize }}</a>
        </div>
      </ppf-card>
    </div>
  </ppf-card>
</div>
