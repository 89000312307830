import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const OMV_VALIDATORS = {
  higherThanPrevious(previousValue): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return parseInt(control.value, 10) < previousValue ? { lowerThanPrevious: { value: control.value } } : null;
    };
  },

  minimOneTrue(): ValidationErrors {
    return (group: FormGroup) => {
      const hasOneValueTrue = Object.keys(group.value).find((control) => {
        return group.controls[control].value;
      });
      return hasOneValueTrue ? null : { minOneOption: true };
    };
  },
};
