import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DashboardEmptyState, DashboardOffer } from '@app/main/dashboard/interfaces';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { AppEventsService } from '@app/shared/services/app-events.service';

@Component({
  selector: 'omc-dashboard-offer',
  templateUrl: './dashboard-offer.component.html',
  styleUrls: ['./dashboard-offer.component.scss'],
})
export class DashboardOfferComponent implements OnChanges {
  @Input() offer: DashboardOffer;
  @Input() contract: DashboardEmptyState;
  @Input() type: string;
  @Input() actions: any;
  public showContent = true;
  public onbBaseUrl = '';

  constructor(private googleAnalyticsService: GoogleAnalyticsService, private appEventsService: AppEventsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contract && changes.contract.currentValue && changes.contract.currentValue.processURL) {
      this.onbBaseUrl = changes.contract.currentValue.processURL;
    }
  }

  doAction(): void {
    if (this.contract.value && this.actions[this.contract.value]) {
      this.actions[this.contract.value]().subscribe(() => {
        if (this.onbBaseUrl) {
          this.appEventsService.setOnbRedirectLink({ baseUrl: this.onbBaseUrl });
          this.googleAnalyticsService.eventEmitter(
            'Redirect to welcome utilio',
            'custom_events',
            'redirect_to_welcome_utilio',
            'click',
            1,
          );
        }
      });
    }
  }
}
