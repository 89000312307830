import { Component, Input } from '@angular/core';
import { Ticket } from '@app/main/tickets/interfaces';
import { ModalService } from 'paperflow-web-components';
import { TicketDetailsModalComponent } from '@app/main/ticket-details-modal/ticket-details-modal.component';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';

@Component({
  selector: 'omc-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss'],
})
export class TicketDetailsComponent {
  @Input() ticket: Ticket;
  @Input() showButton = true;
  @Input() showAllText = false;
  @Input() modalLabel: string;

  modal: any;

  constructor(private modalService: ModalService, private googleAnalyticsService: GoogleAnalyticsService) {}

  openModal(): void {
    this.googleAnalyticsService.eventEmitter(
      'Ticket details',
      'in_app_page_view',
      'ticket_details_screen',
      'open_ticket_details_button_clicked',
      1,
    );

    this.modal = this.modalService.show(
      TicketDetailsModalComponent,
      {
        closeOnBackdropClick: false,
      },
      { ticket: this.ticket, title: this.modalLabel },
    );
  }
}
