import { ComponentAction } from '@app/shared/interfaces';

export interface AddMeterInput {
  collaborationAddReadingInformation: {
    indexReading: {
      image: string;
      title: string;
      info: string;
      indexValues: Array<{
        consumptionPointId: number;
        consumptionPointName: string;
        previousIndexValue: number;
        currentIndexValue: number;
      }>;
      currentReadingTooltip: {
        title: string;
        message: string;
      };
      previousReadingTooltip: {
        title: string;
        message: string;
      };
      errors: {
        invalidInput: string;
        smallerThanPreviousValue: string;
      };
      labels: {
        currentIndexLabel: string;
        previousIndexLabel: string;
      };
      tooltip: {
        message: string;
      };
    };
    actionMetadata: Array<{
      name: string;
      label: string;
    }>;
  };

  actionsFn: Record<string, (params?: Record<string, any>) => void>;
  actions: Array<ComponentAction>;
}

export const ERRORS = {
  maxlength: 'invalidInput',
  lowerThanPrevious: 'smallerThanPreviousValue',
  pattern: 'invalidInput',
};
