<footer class="footer">
  <div class="footer-wrapper">
    <div>
      <div class="copyright-claims mb-16">{{ texts.copyright }}</div>

      <div class="links-wrapper">
        <a (click)="openPrivacy(texts.privacyPolicy)" class="link">{{ texts.privacyPolicy }}</a>
        <a (click)="openUrl(texts.faqUrl, texts.faq)" target="_blank" class="link">{{ texts.faq }}</a>
      </div>
    </div>
    <div>
      <a id="logo">
        <img src="../../assets/omvp-logo.png" class="logo-icon" alt="OMVP logo" />
      </a>
    </div>
  </div>
</footer>

<omc-privacy-modal [hidden]="!showPrivacy" (privacyClosed)="showPrivacy = false"></omc-privacy-modal>
