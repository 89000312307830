import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommodityComponent } from '@app/main/commodity/commodity.component';

@Component({
  selector: 'omc-power-commodity',
  templateUrl: './commodity.component.html',
  styleUrls: ['./commodity.scss'],
})
export class PowerCommodityComponent extends CommodityComponent implements OnInit, OnDestroy {
  public key = 'collaborationPowerInformation';
  public route = 'power';
}
