import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuComponent } from '@app/main/menu/menu.component';
import { FlxViewportGasModule } from '@app/flx-viewport-gas/flx-viewport-gas.module';
import { FlxViewportPowerModule } from '@app/flx-viewport-power/flx-viewport-power.module';
import { FlxViewportTicketsModule } from '@app/flx-viewport-tickets/flx-viewport-tickets.module';
import { FlxViewportDashboardModule } from '@app/flx-viewport-dashboard/flx-viewport-dashboard.module';
import { FlxViewportAccountModule } from '@app/flx-viewport-account/flx-viewport-account.module';
import { FlxViewportNotificationsModule } from '@app/flx-viewport-notifications/flx-viewport-notifications.module';

const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'gas',
        loadChildren: () => import('../flx-viewport-gas/flx-viewport-gas.module').then((m) => FlxViewportGasModule),
      },
      {
        path: 'power',
        loadChildren: () =>
          import('../flx-viewport-power/flx-viewport-power.module').then((m) => FlxViewportPowerModule),
      },
      {
        path: 'support',
        loadChildren: () =>
          import('../flx-viewport-tickets/flx-viewport-tickets.module').then((m) => FlxViewportTicketsModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('../flx-viewport-dashboard/flx-viewport-dashboard.module').then((m) => FlxViewportDashboardModule),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('../flx-viewport-account/flx-viewport-account.module').then((m) => FlxViewportAccountModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('../flx-viewport-notifications/flx-viewport-notifications.module').then(
            (m) => FlxViewportNotificationsModule,
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
