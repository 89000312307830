import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnInit } from '@angular/core';
import { TableComponent } from 'paperflow-web-components';
import { Column } from './table.dictionary';

@Component({
  selector: 'omc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class OmvTableComponent extends TableComponent implements OnChanges, OnInit {
  public tableData: Array<any>;
  public localColumns: Column[] = [];
  public length = 0;
  @Input() columns: Column[] = [];
  @Input() height = '400px';
  @Input() notSortableColumnsIds: Array<string>;
  @Output() sortClicked = new EventEmitter<any>();
  @Output() columnSelected = new EventEmitter<any>();

  ngOnInit(): void {
    this.localColumns = this.columns;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tableData = changes.data.currentValue;
    this.length = changes.data.currentValue.length;
  }

  sort(id: string, direction: string): void {
    this.selectColumn(id);
    this.sortClicked.emit({ id, direction: direction === 'descendant' ? 'ascendant' : 'descendant' });
  }

  selectColumn(id): void {
    // use aux const for table columns update
    const columns = this.localColumns.map((column) => {
      if (column.id === id) {
        column.selected = true;
        column.direction = column.direction === 'descendant' ? 'ascendant' : 'descendant';
      } else {
        column.selected = false;
        column.direction = 'descendant';
      }

      return column;
    });

    this.localColumns = columns;
  }
}
