import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlxProcessModule } from 'flowx-process-renderer';

import { OMV_VALIDATORS } from '@app/shared/validators';
import { LocalDataStoreService } from '@app/core/local-data-store.service';
import { SharedModule } from '@app/shared/shared.module';
import { FlxViewportTicketsRoutingModule } from '@app/flx-viewport-tickets/flx-viewport-tickets-routing.module';
import { FlxViewportTicketsComponent } from '@app/flx-viewport-tickets/flx-viewport-tickets.component';
import { AddMeterReadingComponent } from '@app/main/add-meter-reading/add-meter-reading.component';
import { TicketsMainComponent } from '@app/main/tickets-main/tickets-main.component';
import { PayAllComponent } from '@app/main/pay-all/pay-all.component';
import { ErrorHandlingComponent } from '@app/error-handling/error-handling.component';

@NgModule({
  declarations: [FlxViewportTicketsComponent],
  imports: [
    CommonModule,
    SharedModule,
    FlxViewportTicketsRoutingModule,
    FlxProcessModule.forRoot({
      components: {
        // here will be my custom components
        MarvelTicketsComponent: TicketsMainComponent,
        AddMeterReading: AddMeterReadingComponent,
        PayAllModal: PayAllComponent,
        ErrorHandling: ErrorHandlingComponent,
      },
      services: {
        // here I wil load my custom services (Nomenclator, LocalStorage)
        LocalDataStoreService,
      },
      validators: OMV_VALIDATORS,
    }),
  ],
})
export class FlxViewportTicketsModule {}
