<ppf-modal class="om-modal export-modal">
  <ppf-card class="export-card">
    <div fxLayout="row" fxLayoutAlign="end center" class="title">
      <button class="modal-close" (click)="closeModal()">
        <ppf-icon name="close"></ppf-icon>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <ppf-icon name="export-file" class="export-modal-image"></ppf-icon>
    </div>
    <div class="modal-content">
      <h2 class="modal-title mt-24 mb-8 text-center">{{ title | flxLocalize }}</h2>
      <p class="text-center">{{ subtitle | flxLocalize }}</p>
      <div *ngIf="exportOptions && exportOptions.length" [formGroup]="toggleForm" class="options-box mt-24 mb-24">
        <div *ngFor="let option of exportOptions" fxLayout="row" fxLayoutAlign="start start" class="export-option">
          <ppf-switch *ngIf="option.key" class="attribute-toggle mr-24">
            <input id="attribute_toggle" ppfSwitch type="checkbox" [formControlName]="option.key" />
          </ppf-switch>
          <div class="info-wrapper" fxLayout="column" fxLayoutAlign="center start">
            <div class="info-label">{{ option.label | flxLocalize }}</div>
            <div class="info-description">{{ option.description | flxLocalize }}</div>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
      <ppf-button
        class="export-action-btn"
        [title]="btnLabel | flxLocalize"
        [disabled]="toggleForm.invalid"
        (onClick)="doAction()"
      ></ppf-button>
    </div>
  </ppf-card>
</ppf-modal>
