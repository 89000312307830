import moment from 'moment';
import _ from 'lodash';

export class Utils {
  public static getActionKey(model): any {
    return model.actionsFn ? Object.keys(model.actionsFn)[0] : '';
  }

  public static getActionLabel(modelData): string {
    const action = modelData.actionsMetadata && modelData.actionsMetadata[0];
    return action && action.label ? action.label : '';
  }

  public static doAction(entity, key, payload): void {
    entity.actionsFn[key](payload).subscribe();
  }

  public static setChartValues(
    values: Array<any>,
    legendLabel: string,
    forecastValues: any,
    hasAggregatedInvoices = true,
    hasAmount = false,
    secondLegendLabel = '',
  ): any {
    let aggregatedValues = Utils.sortAndAggregate(values, hasAggregatedInvoices, hasAmount);
    if (forecastValues && forecastValues.length) {
      const localForecastValues = forecastValues.map((forecastValue) => {
        return {
          ...forecastValue,
          forecast: true,
          displayInChart: true,
        };
      });
      aggregatedValues = aggregatedValues.concat(Utils.sortAndAggregate(localForecastValues, false, true));
    }

    return this.setChartData(aggregatedValues, legendLabel, !!forecastValues, secondLegendLabel);
  }

  public static sortAndAggregate(values, hasAggregatedInvoices, hasAmount): Array<any> {
    const aggregatedValues: Array<any> = [];
    _.sortBy(values, (invoice) => new Date(invoice.invoiceDate).getTime()).forEach((value) => {
      if (value.displayInChart) {
        value.gasConsumption = { value: 0, unit: value.consumption.unit };
        value.powerConsumption = { value: 0, unit: value.consumption.unit };

        if (hasAmount) {
          value.amount.value = parseFloat(value.amount.value);
          value.gasAmount = { value: 0, currency: value.amount.currency };
          value.powerAmount = { value: 0, currency: value.amount.currency };
        }

        if (value.type === 'GAS') {
          value.gasConsumption = _.cloneDeep(value.consumption);
          if (hasAmount) {
            value.gasAmount = _.cloneDeep(value.amount);
          }
        }

        if (value.type === 'POWER') {
          value.powerConsumption = _.cloneDeep(value.consumption);
          if (hasAmount) {
            value.powerAmount = _.cloneDeep(value.amount);
          }
        }

        if (hasAggregatedInvoices) {
          aggregatedValues.push(_.cloneDeep(value));
        } else {
          const index = aggregatedValues.findIndex((aggregatedInvoice) => {
            return (
              moment(aggregatedInvoice.invoiceDate).isSame(moment(value.invoiceDate), 'month') &&
              moment(aggregatedInvoice.invoiceDate).isSame(moment(value.invoiceDate), 'year')
            );
          });

          if (index > -1) {
            aggregatedValues[index].consumption.value =
              aggregatedValues[index].consumption.value + value.consumption.value;
            aggregatedValues[index].gasConsumption.value =
              aggregatedValues[index].gasConsumption.value + value.gasConsumption.value;
            aggregatedValues[index].powerConsumption.value =
              aggregatedValues[index].powerConsumption.value + value.powerConsumption.value;
            if (hasAmount) {
              aggregatedValues[index].amount.value = aggregatedValues[index].amount.value + value.amount.value;
              aggregatedValues[index].gasAmount.value = aggregatedValues[index].gasAmount.value + value.gasAmount.value;
              aggregatedValues[index].powerAmount.value =
                aggregatedValues[index].powerAmount.value + value.powerAmount.value;
            }
          } else if (moment().diff(value.invoiceDate, 'years') < 1) {
            aggregatedValues.push(_.cloneDeep(value));
          }
        }
      }
    });
    return aggregatedValues;
  }

  public static setChartData(
    values: Array<any>,
    legendLabel: string,
    hasForecast: boolean,
    secondLegendLabel: string,
  ): any {
    const config = {
      title: values.map((value) => value.graphDisplayItems.tooltipMonth),
      label: legendLabel,
      xAxisID: 'history',
      backgroundColor: '#bec9da',
      hoverBackgroundColor: '#94a4c1',
      maxBarThickness: 80,
    };

    const barChartLabels = values.map((value) => value.graphDisplayItems.barFooterMonth);
    let barChartData;

    if (!hasForecast) {
      barChartData = [
        {
          data: values.map((value) => {
            return value.consumption.value.toFixed(2);
          }),
          consumption: values.map((value) => {
            return value.consumption.value.toFixed(2) + ' ' + value.consumption.unit;
          }),
          ...config,
        },
      ];
    } else {
      barChartData = [
        {
          data: values.map((value) => {
            if (!value.forecast) {
              return value.amount.value;
            }
          }),
          gasConsumption: values.map((value) =>
            value.gasConsumption.value
              ? value.gasConsumption.value.toFixed(2) + ' ' + value.gasConsumption.unit
              : false,
          ),
          powerConsumption: values.map((value) =>
            value.powerConsumption.value
              ? value.powerConsumption.value.toFixed(2) + ' ' + value.powerConsumption.unit
              : false,
          ),
          gasAmount: values.map((value) => {
            if (!value.forecast) {
              return value.gasAmount.value ? value.gasAmount.value.toFixed(2) + ' ' + value.gasAmount.currency : false;
            }
            return false;
          }),
          powerAmount: values.map((value) => {
            if (!value.forecast) {
              return value.powerAmount.value
                ? value.powerAmount.value.toFixed(2) + ' ' + value.powerAmount.currency
                : false;
            }
            return false;
          }),
          ...config,
        },
        {
          data: values.map((value) => {
            if (value.forecast) {
              return value.amount.value;
            }
          }),
          gasConsumption: values.map((value) =>
            value.gasConsumption.value
              ? value.gasConsumption.value.toFixed(2) + ' ' + value.gasConsumption.unit
              : false,
          ),
          powerConsumption: values.map((value) =>
            value.powerConsumption.value
              ? value.powerConsumption.value.toFixed(2) + ' ' + value.powerConsumption.unit
              : false,
          ),
          gasAmount: values.map((value) => {
            if (value.forecast) {
              return value.gasAmount.value ? value.gasAmount.value.toFixed(2) + ' ' + value.gasAmount.currency : false;
            }

            return false;
          }),
          powerAmount: values.map((value) => {
            if (value.forecast) {
              return value.powerAmount.value
                ? value.powerAmount.value.toFixed(2) + ' ' + value.powerAmount.currency
                : false;
            }
            return false;
          }),
          title: values.map((value) => value.graphDisplayItems.tooltipMonth),
          label: secondLegendLabel,
          xAxisID: 'forecast',
          backgroundColor: '#ffe44e',
          hoverBackgroundColor: '#f0d23c',
          maxBarThickness: 80,
        },
      ];
    }

    return { barChartLabels, barChartData };
  }

  public static getModuleStartData(state: any, guid: string): any {
    const data = {
      deviceIdentifier: 'web',
      frontendBaseUrl: window.location.href,
      guid,
    };
    return state.processStartData
      ? {
          ...state.processStartData,
          ...data,
        }
      : data;
  }
}
