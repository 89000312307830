import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import _ from 'lodash';

import { PayAllInput } from '@app/main/pay-all/interfaces';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { LoaderService } from '@app/shared/services/loader.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'omc-pay-all',
  templateUrl: './pay-all.component.html',
  styleUrls: ['./pay-all.component.scss'],
})
export class PayAllComponent implements OnInit, OnDestroy {
  @Input() data$: Observable<PayAllInput>;
  public modalData$: Observable<PayAllInput>;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private loader: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    document.body.classList.add('no-scroll');
    this.loader.setLoaderState({ loading: false, type: 'normal' });

    this.modalData$ = this.data$.pipe(
      tap((data) => {
        if (data && data.payUResponse && data.payUResponse.url && !this.route.snapshot.queryParams.paymentStarted) {
          this.router
            .navigate(['.'], {
              relativeTo: this.route,
              queryParams: { paymentStarted: true },
              queryParamsHandling: 'merge',
            })
            .then(() => {
              window.open((data as any).payUResponse.url, '_self');
            });
        }
      }),
    );
  }

  getActionLabel(data, payAll): string {
    const payAction = _.filter(data.actionsMetadata, (action) =>
      payAll ? action.name === 'pay_all' : action.name === 'pay_one',
    );
    return payAction.length ? payAction[0].label : '';
  }

  doAction(data): void {
    this.googleAnalyticsService.eventEmitter(
      'Pay outstanding invoices',
      'custom_events',
      'pay_outstanding',
      'click',
      1,
    );
    data.actionsFn.pay_action().subscribe();
  }

  closeModal(data): void {
    data.actionsFn.dismiss_process().subscribe();
  }

  ngOnDestroy(): void {
    document.body.classList.remove('no-scroll');
  }
}
