export const arrowLeft = {
  name: 'arrowLeft',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="6.401" height="10.715" viewBox="0 0 6.401 10.715">
    <path id="arrow" fill="currentColor" d="M5.658 9.8L.3 4.444 1.274 3.4l4.384 4.384L10.041 3.4l.974 1.044z" transform="rotate(90 5.05 4.75)"/>
</svg>
`,
};

export const arrowRight = {
  name: 'arrowRight',
  data: `<svg xmlns="http://www.w3.org/2000/svg" style="transform: scale(-1,1)" width="6.401" height="10.715" viewBox="0 0 6.401 10.715">
    <path id="arrow" fill="currentColor" d="M5.658 9.8L.3 4.444 1.274 3.4l4.384 4.384L10.041 3.4l.974 1.044z" transform="rotate(90 5.05 4.75)"/>
</svg>`,
};

export const arrowDown = {
  name: 'arrowDown',
  data: `<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.292969 1.70711L1.70718 0.292895L5.00008 3.58579L8.29297 0.292894L9.70718 1.70711L5.00008 6.41421L0.292969 1.70711Z" fill="#6685A3"/>
</svg>`,
};

export const buttonArrowRight = {
  name: 'buttonArrowRight',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="#4f6f9b" transform="translate(-.367 -.367)">
        <rect width="40" height="40" opacity="0.1" rx="8" transform="translate(.367 .367)"/>
        <path d="M9.441 1.3L8.02 2.721l4.365 4.365H0v2.03h12.385L8.02 13.482 9.441 14.9l6.8-6.8z" transform="translate(12.246 11.884)"/>
    </g>
</svg>`,
};

export const buttonArrowLeft = {
  name: 'buttonArrowLeft',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="12.76" height="10.686" viewBox="0 0 12.76 10.686">
    <path fill="#404650" d="M5.343 1.3L6.46 2.416l-3.43 3.43h9.73v1.595H3.03l3.43 3.429-1.117 1.116L0 6.643z" opacity="0.3" transform="translate(0 -1.3)"/>
</svg>`,
};

export const magnifier = {
  name: 'magnifier',
  data:
    '<svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16" viewBox="0 0 16.001 16">\n' +
    '    <path id="search" d="M-1945.707 15.707l-2.392-2.393a8.026 8.026 0 0 0 1.414-1.414l2.393 2.393a1 1 0 0 1 0 1.414A1 1 0 0 1-1945 16a1 1 0 0 1-.707-.293zM-1960 7a7.008 7.008 0 0 1 7-7 7.008 7.008 0 0 1 7 7 7.008 7.008 0 0 1-7 7 7.008 7.008 0 0 1-7-7zm2 0a5.006 5.006 0 0 0 5 5 5.006 5.006 0 0 0 5-5 5.006 5.006 0 0 0-5-5 5.006 5.006 0 0 0-5 5z" class="cls-1" transform="translate(1960)"/>\n' +
    '</svg>',
};

export const question = {
  name: 'question',
  data: `<svg xmlns="http://www.w3.org/2000/svg" id="c-info" viewBox="0 0 16 16">
    <g id="bulb-62" transform="translate(4.028 2.704)">
        <path id="Path_14597" fill="#fff" d="M5.972 0A3.984 3.984 0 0 0 2 3.972a4.033 4.033 0 0 0 1.986 3.442V9.93a.625.625 0 0 0 .662.662H7.3a.625.625 0 0 0 .662-.662V7.414a4.033 4.033 0 0 0 1.982-3.442A3.984 3.984 0 0 0 5.972 0zM5.31 9.268v-.662h1.324v.662zm1.721-2.847a.579.579 0 0 0-.4.6v.927H5.31v-.931a.579.579 0 0 0-.4-.6 2.648 2.648 0 1 1 2.118 0z" data-name="Path 14597" transform="translate(-2)"/>
    </g>
    <g id="c-question_4_" data-name="c-question (4)">
        <path id="Path_15219" d="M8 0a8 8 0 1 0 8 8 8.024 8.024 0 0 0-8-8zm0 13a1 1 0 0 1 0-2 1 1 0 0 1 0 2zm1.5-4.6c-.5.3-.5.4-.5.6v1H7V9a2.614 2.614 0 0 1 1.4-2.3c.5-.3.6-.4.6-.7a.945.945 0 0 0-1-1 1.05 1.05 0 0 0-.9.5l-.5.9-1.7-1 .5-.9A2.984 2.984 0 0 1 8 3a2.946 2.946 0 0 1 3 3 2.722 2.722 0 0 1-1.5 2.4z" data-name="Path 15219"/>
    </g>
</svg>
`,
};

export const menuAccount = {
  name: 'menu-account-icon',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="account">
        <path id="Path_16269" d="M12.25 8.231a5.99 5.99 0 0 1-8.5 0A7.985 7.985 0 0 0 0 15v1h16v-1a7.985 7.985 0 0 0-3.75-6.769z"/>
        <circle id="Ellipse_318" cx="4" cy="4" r="4" class="cls-2" transform="translate(4)"/>
    </g>
</svg>`,
};

export const menuConsumption = {
  name: 'menu-consumption-icon',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g>
        <path d="M8 0a8 8 0 1 0 8 8 8.024 8.024 0 0 0-8-8zm0 10a2.006 2.006 0 0 1-2-2 .9.9 0 0 1 .1-.5L3.6 5 5 3.6l2.5 2.5A.9.9 0 0 1 8 6a2 2 0 0 1 0 4z"/>
    </g>
</svg>
`,
};

export const menuDashboard = {
  name: 'menu-dashboard-icon',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="layout-11">
        <path id="Path_16258" d="M6 9H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1z"/>
        <path id="Path_16259" d="M6 16H1a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1z"/>
        <path id="Path_16260" d="M15 6h-5a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1z"/>
        <path id="Path_16261" d="M15 16h-5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1z"/>
    </g>
</svg>`,
};

export const menuInvoice = {
  name: 'menu-invoice-icon',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
    <g>
        <path  d="M12 2l-2-2-2 2-2-2-2 2-3-2v15a.945.945 0 0 0 1 1h12a.945.945 0 0 0 1-1V0zm0 10H4v-2h8zm0-4H4V6h8z" transform="translate(-1)"/>
    </g>
</svg>`,
};

export const menuNews = {
  name: 'menu-news-icon',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="14.026" viewBox="0 0 16 14.026">
    <g id="news" transform="translate(0 -1)">
        <path id="Path_16267" d="M4 8H0v4.9A2.14 2.14 0 0 0 1.7 15 2.025 2.025 0 0 0 4 13z"/>
        <path id="Path_16268" d="M15 1H7a.945.945 0 0 0-1 1v11a3.606 3.606 0 0 1-.6 2H13a2.946 2.946 0 0 0 3-3V2a.945.945 0 0 0-1-1z"/>
    </g>
</svg>`,
};

export const menuGas = {
  name: 'menu-gas-icon',
  data: `<svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 0C3.54993 2.74964 2.58775 3.68476 1.61025 4.63476C0.810256 5.41225 0 6.19972 0 8C0 9.75114 0.900214 11.292 2.26311 12.1851C2.09406 11.8 1.99999 11.3729 1.99999 10.9231C1.99999 9.24477 2.91834 7.93128 3.45051 7.17018L3.45052 7.17016C3.62078 6.92666 3.75152 6.73968 3.79999 6.61538C3.79999 6.96796 3.75622 7.18587 3.71884 7.37199C3.65835 7.67313 3.61458 7.89104 3.79999 8.46153C4.39999 7.23076 5.7 6.20513 6.2 6C5.87004 7.69208 6.44735 8.26753 7.03385 8.85215C7.51385 9.3306 8 9.81528 8 10.9231C8 11.3729 7.90593 11.8 7.73688 12.1851C9.09978 11.292 10 9.75114 10 8C10 6 9.5 3 7 1C7 1 8 3 7 4C6 1.5 3.83333 0.333333 3 0Z" fill="#59B300"/>
</svg>`,
};

export const menuPower = {
  name: 'menu-power-icon',
  data: `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3 0C2.44772 0 2 0.447715 2 1V3H1C0.447715 3 0 3.44772 0 4C0 4.55228 0.447715 5 1 5L1 6C1 8.20914 2.79086 10 5 10C7.20914 10 9 8.20914 9 6V5C9.55229 5 10 4.55228 10 4C10 3.44772 9.55228 3 9 3H8V1C8 0.447715 7.55228 0 7 0C6.44772 0 6 0.447715 6 1V3H4V1C4 0.447715 3.55228 0 3 0ZM7 6H3C3 7.10457 3.89543 8 5 8C6.10457 8 7 7.10457 7 6Z" fill="#6685A3"/>
</svg>`,
};

export const menuReports = {
  name: 'menu-reports-icon',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
    <g id="edit-curves_1_" transform="translate(0 -1)">
        <path id="Path_16264" d="M16 15H1a1 1 0 0 1-1-1V1h2v12h14z"/>
        <path id="Path_16265" d="M16 12H3v-2c2.912 0 3.889-1.759 5.126-3.985C9.432 3.664 10.912 1 15 1h1z"/>
    </g>
</svg>`,
};

export const menuServices = {
  name: 'menu-services-icon',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16.028" height="16.068" viewBox="0 0 16.028 16.068">
    <g>
        <path d="M13.2 5.2l-2.4-2.4L13.2.4a3.287 3.287 0 0 0-1.7-.4A4.481 4.481 0 0 0 7 4.5a6.753 6.753 0 0 0 .2 1.4L.6 10.8a1.641 1.641 0 0 0-.2 2.4l2.3 2.4a1.568 1.568 0 0 0 2.4-.2L10 8.8a7.719 7.719 0 0 0 1.5.2A4.481 4.481 0 0 0 16 4.5a4.671 4.671 0 0 0-.3-1.7z" transform="translate(.028)"/>
    </g>
</svg>`,
};

export const menuSupport = {
  name: 'menu-support-icon',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16.009" height="16" viewBox="0 0 16.009 16">
    <g id="call-doctor">
        <path id="Path_16270" d="M13.167 11.021L10.45 9.814a1.4 1.4 0 0 0-1.662.4l-.783.979A10.7 10.7 0 0 1 4.8 8l.979-.783a1.4 1.4 0 0 0 .4-1.661l-1.2-2.724a1.4 1.4 0 0 0-1.632-.787l-2.3.6A1.4 1.4 0 0 0 .014 4.2a13.91 13.91 0 0 0 11.792 11.786 1.4 1.4 0 0 0 1.552-1.037l.6-2.3a1.394 1.394 0 0 0-.791-1.628z"/>
        <path id="Path_16271" d="M12 0a3.993 3.993 0 0 0-3.617 5.7L8 8l2.3-.383A4 4 0 1 0 12 0z"/>
    </g>
</svg>`,
};

export const sortArrowUp = {
  name: 'sort-arrow-up',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="5.987" viewBox="0 0 8 5.987">
    <path d="M5.8 4.4l-5 3.5a.5.5 0 0 1-.8-.4v-7A.5.5 0 0 1 .8.1l5 3.5a.52.52 0 0 1 0 .8z" transform="rotate(-90 2.994 2.994)"/>
</svg>`,
};

export const sortArrowDown = {
  name: 'sort-arrow-down',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="5.987" viewBox="0 0 8 5.987">
    <path d="M5.8 3.6L.8.1a.5.5 0 0 0-.8.4v7a.5.5 0 0 0 .8.4l5-3.5a.52.52 0 0 0 0-.8z" transform="rotate(90 4 4)"/>
</svg>`,
};

export const money = {
  name: 'money',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <defs>
            <style>
            </style>
          </defs>
          <g id="Group_20686">
            <path id="Rectangle_2343" fill="transparent" d="M0 0H16V16H0z"/>
          <g id="coins" transform="translate(3 2)">
            <path id="Path_16281" d="M7 5c2.417 0 5-.75 5-2.5S9.417 0 7 0 2 .75 2 2.5 4.583 5 7 5z" transform="translate(-2)"/>
            <path id="Path_16282" d="M2 6.5v1.25c0 1.75 2.583 2.5 5 2.5s5-.75 5-2.5V6.5a9.4 9.4 0 0 1-5 1.25A9.4 9.4 0 0 1 2 6.5z" transform="translate(-2 -2.083)"/>
            <path id="Path_16283" d="M2 11.5v1.25c0 1.75 2.583 2.5 5 2.5s5-.75 5-2.5V11.5a8.7 8.7 0 0 1-5 1.25 9.4 9.4 0 0 1-5-1.25z" transform="translate(-2 -3.917)"/>
          </g>
          </g>
        </svg>`,
};

export const add = {
  name: 'add',
  data: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="8" fill="#414751"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9 4H7V7H4V9H7V12H9V9H12V7H9V4Z" fill="white"/>
</svg>`,
};

export const exportFile = {
  name: 'export-file',
  data: `<svg xmlns="http://www.w3.org/2000/svg" id="document-export" width="199" height="199" viewBox="0 0 199 199">
    <defs>
        <style>
            .cls-2{fill:#6177ae}.cls-3{fill:#8398cc}.cls-5{fill:#fff}
        </style>
    </defs>
    <g id="Layer_1">
        <g id="document-export-2">
            <rect id="Rectangle_2549" width="199" height="199" fill="#f2f2f2" rx="28"/>
            <path id="Path_17130" d="M41.49 142.54h81.864V56.958L101.028 34.62H41.49z" class="cls-2" transform="translate(10.113 8.439)"/>
            <path id="Path_17131" d="M123.719 144.769H41.856a1.866 1.866 0 0 1-1.866-1.869V34.983a1.866 1.866 0 0 1 1.866-1.853h59.538a1.866 1.866 0 1 1 0 3.731h-57.66V141.05h78.157V57.321a1.866 1.866 0 1 1 3.731 0V142.9a1.866 1.866 0 0 1-1.9 1.866z" class="cls-2" transform="translate(9.748 8.075)"/>
            <path id="Path_17132" d="M93 56.967a3.657 3.657 0 0 1-3.644-3.731V34.58l22.251 22.387z" class="cls-3" transform="translate(21.781 8.429)"/>
            <path id="Path_17133" d="M112.063 59.172H89.676a1.866 1.866 0 0 1-1.866-1.853V34.931a1.866 1.866 0 0 1 3.184-1.244l22.039 22.039a1.866 1.866 0 0 1-.97 3.445zM91.6 55.44h15.908L91.6 39.483z" class="cls-3" transform="translate(21.404 8.078)"/>
            <g id="secure_payment_gateway" transform="translate(102.895 117.373)">
                <g id="encryption">
                    <circle id="Ellipse_348" cx="24.104" cy="24.104" r="24.104" fill="#f8dd4b"/>
                </g>
            </g>
            <path id="Path_17135" d="M76.544 90.124H48.87a2.313 2.313 0 0 1 0-4.614h27.674a2.313 2.313 0 0 1 0 4.614z" class="cls-5" transform="translate(11.39 20.843)"/>
            <path id="Path_17137" d="M67.315 82.706H48.87a2.313 2.313 0 0 1 0-4.614h18.445a2.313 2.313 0 1 1 0 4.614z" class="cls-5" transform="translate(11.39 19.033)"/>
            <path id="Path_17139" d="M99.837 82.706h-13.83a2.313 2.313 0 1 1 0-4.614h13.83a2.313 2.313 0 0 1 0 4.614z" class="cls-5" transform="translate(20.359 19.033)"/>
            <path id="Path_17141" d="M80.329 82.7H71.1a2.313 2.313 0 0 1 0-4.614h9.229a2.313 2.313 0 0 1 0 4.614z" class="cls-5" transform="translate(16.808 19.034)"/>
            <path id="Path_17143" d="M62.721 75.282h-13.83a2.3 2.3 0 1 1 0-4.6h13.83a2.3 2.3 0 1 1 0 4.6z" class="cls-5" transform="translate(11.356 17.228)"/>
            <path id="Path_17145" d="M108.942 75.282H67.451a2.3 2.3 0 0 1 0-4.6h41.5a2.3 2.3 0 0 1 0 4.6z" class="cls-5" transform="translate(15.88 17.228)"/>
            <path id="Path_17147" d="M104.395 67.906H85.95a2.313 2.313 0 0 1 0-4.614h18.445a2.313 2.313 0 1 1 0 4.614z" class="cls-5" transform="translate(20.428 15.426)"/>
            <path id="Path_17149" d="M85.76 67.906H48.87a2.313 2.313 0 0 1 0-4.614h36.89a2.313 2.313 0 1 1 0 4.614z" class="cls-5" transform="translate(11.39 15.426)"/>
            <path id="Path_17150" d="M101.512 118.92a1.617 1.617 0 0 1-1.119-.46 1.592 1.592 0 0 1 0-2.264l11.268-11.418a1.6 1.6 0 0 1 2.276 2.251l-11.268 11.418a1.629 1.629 0 0 1-1.157.473z" class="cls-5" transform="translate(24.356 25.442)"/>
            <path id="Path_17151" d="M112.053 113.952a1.6 1.6 0 0 1-1.6-1.6v-5.162h-5.162a1.6 1.6 0 0 1 0-3.2h6.809a1.592 1.592 0 0 1 1.592 1.6v6.754a1.592 1.592 0 0 1-1.642 1.6z" class="cls-5" transform="translate(25.307 25.348)"/>
            <path id="Path_17152" d="M112.112 127.635H94.1a1.592 1.592 0 0 1-1.592-1.6v-18a1.592 1.592 0 0 1 1.592-1.592h9.353a1.6 1.6 0 1 1 0 3.2h-7.747v14.8h14.825v-7.753a1.6 1.6 0 1 1 3.2 0v9.341a1.592 1.592 0 0 1-1.592 1.6z" class="cls-5" transform="translate(22.549 25.944)"/>
        </g>
    </g>
</svg>`,
};

export const download = {
  name: 'download',
  data: `<svg xmlns="http://www.w3.org/2000/svg" id="data-download" width="12.163" height="12.163" viewBox="0 0 12.163 12.163">
    <path id="Path_16375" d="M6.465 9.122A.69.69 0 0 0 7 8.894l4.33-4.333L10.266 3.5 7.225 6.537V0H5.7v6.537L2.664 3.5 1.6 4.561l4.333 4.333a.69.69 0 0 0 .532.228z" class="cls-1" transform="translate(-.384)"/>
    <path id="Path_16376" d="M10.642 13.28H1.52V11H0v3.041a.718.718 0 0 0 .76.76H11.4a.718.718 0 0 0 .76-.76V11h-1.52z" class="cls-1" transform="translate(0 -2.638)"/>
</svg>`,
};

export const logout = {
  name: 'logout',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16.003" height="16.002" viewBox="0 0 16.003 16.002">
    <g id="logout" transform="translate(0 -.004)">
        <path id="Path_16856" d="M10 8.672a.667.667 0 0 0-.667.667v2.667a.667.667 0 0 1-.667.667h-2v-10A1.344 1.344 0 0 0 5.76 1.4l-.2-.066h3.108A.667.667 0 0 1 9.335 2v2a.667.667 0 1 0 1.333 0V2a2 2 0 0 0-2-2H1.5a.524.524 0 0 0-.071.015C1.4.016 1.366 0 1.334 0A1.335 1.335 0 0 0 0 1.337v12a1.344 1.344 0 0 0 .908 1.268l4.013 1.338a1.38 1.38 0 0 0 .413.062 1.335 1.335 0 0 0 1.333-1.334v-.667h2a2 2 0 0 0 2-2V9.339A.667.667 0 0 0 10 8.672zm0 0"/>
        <path id="Path_16857" d="M284.471 109.528l-2.667-2.667a.667.667 0 0 0-1.138.471v2H278a.667.667 0 1 0 0 1.333h2.667v2a.667.667 0 0 0 1.138.471l2.667-2.667a.666.666 0 0 0-.001-.941zm0 0" transform="translate(-268.664 -103.328)"/>
    </g>
</svg>`,
};

export const notification = {
  name: 'notification',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="14.172" height="16.002" viewBox="0 0 14.172 16.002">
    <g id="bell" transform="translate(-53.013 -78.836)">
        <path id="Path_16858" d="M184.9 465.044a2.7 2.7 0 0 0 4.945 0z" transform="translate(-127.277 -371.819)"/>
        <path id="Path_16860" d="M66.647 91.3H53.552a.534.534 0 0 1-.526-.409.509.509 0 0 1 .28-.579 2.779 2.779 0 0 0 .846-1.15 13.283 13.283 0 0 0 .882-5.256 5.066 5.066 0 0 1 10.132-.02v.02a13.283 13.283 0 0 0 .882 5.256 2.778 2.778 0 0 0 .846 1.15.509.509 0 0 1 .28.579.534.534 0 0 1-.527.409zm.252-.985z"/>
    </g>
</svg>`,
};

export const phone = {
  name: 'phone',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="14.004" height="14" viewBox="0 0 14.004 14">
    <g>
        <path d="M13.167 11.021L10.45 9.814a1.4 1.4 0 0 0-1.662.4l-.783.979A10.7 10.7 0 0 1 4.8 8l.979-.783a1.4 1.4 0 0 0 .4-1.661l-1.2-2.724a1.4 1.4 0 0 0-1.632-.787l-2.3.6A1.4 1.4 0 0 0 .014 4.2a13.91 13.91 0 0 0 11.792 11.786 1.4 1.4 0 0 0 1.552-1.037l.6-2.3a1.394 1.394 0 0 0-.791-1.628z" transform="translate(0 -2)"/>
    </g>
</svg>`,
};

export const documentCheck = {
  name: 'documentCheck',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="104.115" height="111.489" viewBox="0 0 104.115 111.489">
    <defs>
        <filter id="Path" width="104.115" height="111.489" x="0" y="0" filterUnits="userSpaceOnUse">
            <feOffset dy="3"/>
            <feGaussianBlur result="blur" stdDeviation="12"/>
            <feFlood flood-opacity=".114"/>
            <feComposite in2="blur" operator="in"/>
            <feComposite in="SourceGraphic"/>
        </filter>
        <style>
            .cls-4{fill-rule:evenodd;fill:#4f6f9b}
        </style>
    </defs>
    <g id="Group_21266" transform="translate(22 23)">
        <g id="Group_20182">
            <g id="Group_20177">
                <g id="Group_19602">
                    <rect id="Rectangle_2137" width="60" height="60" fill="#ebebeb" opacity="0.45" rx="8"/>
                </g>
            </g>
        </g>
        <g id="Group_21263" transform="translate(-227 -7153.612)">
            <g id="Page-1" transform="translate(241 7163.612)">
                <g id="_018---Document">
                    <g filter="url(#Path)" transform="translate(-36 -33)">
                        <path id="Path-2" fill="#fff" fill-rule="evenodd" d="M32.115 10.437v27.446a1.611 1.611 0 0 1-1.606 1.606H1.606A1.611 1.611 0 0 1 0 37.883V1.606A1.611 1.611 0 0 1 1.606 0h20.072z" transform="translate(36 33)"/>
                    </g>
                    <path id="Path-3" fill="#4f6f9b" fill-rule="evenodd" d="M37.437 10.437h-8.831A1.611 1.611 0 0 1 27 8.832V0z" opacity="0.1" transform="translate(-5.322)"/>
                    <g id="Group_20184" transform="translate(3.212 3.212)">
                        <path id="Path-4" d="M10.423 9.606H4.8A.8.8 0 1 1 4.8 8h5.62a.8.8 0 0 1 0 1.606z" class="cls-4" transform="translate(-4 -4.788)"/>
                        <path id="Path-5" d="M15.24 5.606H4.8A.8.8 0 0 1 4.8 4h10.44a.8.8 0 0 1 0 1.606z" class="cls-4" transform="translate(-4 -4)"/>
                    </g>
                </g>
            </g>
            <g id="Group_20186" transform="translate(248.995 7179.357)">
                <circle id="Oval" cx="8.266" cy="8.266" r="8.266" fill="#4f6f9b"/>
                <g id="f-check_1_" transform="translate(2.485 4.138)">
                    <path id="Path_15987" fill="#fff" d="M4.135 11.112L1 7.976l1.254-1.254L4.135 8.6l4.39-4.39 1.254 1.258z" transform="translate(.414 -3.103)"/>
                </g>
            </g>
        </g>
    </g>
</svg>`,
};
