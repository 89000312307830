<div fxLayout="row" class="notification-wrapper">
  <img [src]="imgSrc" alt="notification image" class="notification-status-image mr-16" />
  <div class="notification-content">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="notification-title" fxLayout="row">
        <h3>{{ title }}</h3>
      </div>
      <div fxLayout="row">
        <div class="open-details-button">
          <ppf-button
            *ngIf="showButton"
            type="flat"
            leftIconName="magnifier"
            (onClick)="triggerOpenModal()"
          ></ppf-button>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start-center">
      <ppf-icon name="calendar"></ppf-icon>
      <span class="ml-8">{{ date }}</span>
    </div>
    <p [ngClass]="{ 'ticket-message': !showAllText }">{{ message }}</p>
  </div>
</div>
