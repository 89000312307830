import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';
import { cloneDeep } from 'lodash';
import { NotificationSettings } from '@app/main/notification-settings/Interfaces';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';

@Component({
  selector: 'omc-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent implements OnInit {
  @Input() initialData: NotificationSettings;
  @Input() actionsFn: any;
  @Input() actionMetadata: any;
  public localData;

  constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.googleAnalyticsService.eventEmitter(
      'Notification settings',
      'in_app_page_view',
      'notification_settings_screen',
      'account_card_clicked',
      1,
    );
    this.localData = cloneDeep(this.initialData);
  }

  buttonDisabled(index): boolean {
    return _.isEqual(this.initialData[index].options, this.localData[index].options);
  }

  getActionLabel(key): string {
    const actionObject = _.filter(this.actionMetadata, (action) => action.name === key);
    return actionObject && actionObject[0] ? actionObject[0].label : '';
  }

  doAction(name, index): void {
    const payload: {
      notificationSettings: Array<{ key: string; value: string }>;
    } = { notificationSettings: [] };

    this.localData[index].options.forEach((newValue) => {
      this.initialData[index].options.forEach((oldValue) => {
        if (newValue.key === oldValue.key && newValue.value !== oldValue.value) {
          payload.notificationSettings.push({
            key: newValue.key,
            value: newValue.value,
          });
        }
      });
    });

    this.actionsFn[name](payload).subscribe();
    this.sendGoogleEvent(name);
  }

  sendGoogleEvent(section): void {
    this.googleAnalyticsService.eventEmitter(
      'Save notification settings',
      'custom_events',
      'save_notification_settings',
      section + '_save_button_clicked',
      1,
    );
  }
}
