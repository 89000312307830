import { Component, Input, OnInit } from '@angular/core';
import { UserDocument } from '@app/main/user-documents/interfaces';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { ExportFileService } from '@app/shared/services/export-file.service';

@Component({
  selector: 'omc-user-documents',
  templateUrl: './user-documents.component.html',
  styleUrls: ['./user-documents.component.scss'],
})
export class UserDocumentsComponent implements OnInit {
  @Input() data: Array<UserDocument>;
  @Input() labels: any;

  constructor(private googleAnalyticsService: GoogleAnalyticsService, private exportFileService: ExportFileService) {}

  ngOnInit(): void {
    this.googleAnalyticsService.eventEmitter(
      'Documents',
      'in_app_page_view',
      'documents_screen',
      'account_card_clicked',
      1,
    );
  }

  downloadPdf(url, name): void {
    this.googleAnalyticsService.eventEmitter('Download document', 'custom_events', 'download_document', 'click', 1);
    this.exportFileService.downloadPdf(url, name, 'user_document');
  }
}
