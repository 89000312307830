<ppf-button
  [type]="'flat'"
  [leftIconName]="menuIcon"
  [title]="menuTitle"
  [ngClass]="'ppf-button'"
  (onClick)="trigger($event)"
></ppf-button>

<ppf-card class="ppf-overlay" [ngStyle]="{ 'bottom.px': getStyle() }" *ngIf="showMenu">
  <ppf-button
    (click)="handleClick(item)"
    *ngFor="let item of menuItems"
    class="ppf-overlay-button"
    [leftIconName]="item.icon ? item.icon : null"
    [autoSize]="true"
    [title]="item.label"
    [type]="'flat'"
  ></ppf-button>

  <ng-content></ng-content>
</ppf-card>
