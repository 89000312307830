import { Component, OnInit, Renderer2 } from '@angular/core';
import { ModalService } from 'paperflow-web-components';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

import * as copywrite from '../../../assets/global.json';
import { SidebarLink, SidebarUserInfo } from '@app/main/menu/dictionary';
import { AppEventsService } from '@app/shared/services/app-events.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { delay } from 'rxjs/operators';
import { LoaderService } from '@app/shared/services/loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'omc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public texts = copywrite.menu;
  public sideBarLinks: Array<SidebarLink> = [
    {
      label: this.texts.dashboard,
      icon: 'dashboard-icon',
      route: 'dashboard',
    },
    {
      label: this.texts.gas,
      icon: 'gas-icon',
      route: 'gas',
    },
    {
      label: this.texts.power,
      icon: 'power-icon',
      route: 'power',
    },
    {
      label: this.texts.account,
      icon: 'account-icon',
      route: 'account',
    },
    {
      label: this.texts.support,
      icon: 'support-icon',
      route: 'support',
    },
  ];
  public accountInfo: SidebarUserInfo;
  private appEvent$ = this.appEventsService.appEvent$;

  private activeMenuItem$ = this.appEventsService.activeMenuItem$;
  public activeMenuItem = '';
  modal: any;
  public loaderState$: Observable<{ loading: boolean; type: string }>;

  constructor(
    public modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private appEventsService: AppEventsService,
    private readonly oauthService: OAuthService,
    private renderer: Renderer2,
    private googleAnalyticsService: GoogleAnalyticsService,
    private loader: LoaderService,
  ) {}

  ngOnInit(): void {
    this.loaderState$ = this.loader.loader$;

    this.appEventsService.paymentCallback();

    // use delay to avoid ng expressions changed error
    this.appEvent$.pipe(delay(0)).subscribe((data) => {
      this.accountInfo = data.accountInfo;
    });

    this.activeMenuItem$.subscribe((data) => {
      this.activeMenuItem = data.activeMenuItem;
      if (this.activeMenuItem) {
        this.appEventsService.paymentCancelledCallback(this.activeMenuItem);
      }
    });
  }

  get filteredSidebarLinks(): Array<{ label: string; icon: string; route: string }> {
    if (this.accountInfo) {
      switch (this.accountInfo.type) {
        case 'GAS':
          return this.sideBarLinks.filter((item) => item.route !== 'power');
        case 'POWER':
          return this.sideBarLinks.filter((item) => item.route !== 'gas');
        default:
          return this.sideBarLinks;
      }
    }

    return this.sideBarLinks;
  }

  // TODO move to service if needed
  getInitials(name): string {
    const nameArray = name.split(' ');

    let initials = '';

    if (nameArray.length === 1) {
      return nameArray[0].charAt(0) + '' + nameArray[0].charAt(1);
    } else {
      initials = nameArray[0].charAt(0);
    }

    for (let i = nameArray.length - 1; i < nameArray.length; i++) {
      initials += nameArray[i].charAt(0);
    }
    return initials.toUpperCase();
  }

  doAItemAction(item): void {
    if (this.activeMenuItem === item.route) {
      return;
    }

    this.renderer.addClass(document.body, 'child-view-loader');
    this.renderer.addClass(document.body, 'hide-loading-backdrop');

    this.activeMenuItem = item.route;
    this.appEventsService.setActiveMenuItem({ activeMenuItem: item.route });

    this.router.navigate(['main', item.route]);
  }

  payAction(type): void {
    document.body.classList.add('no-scroll');

    this.loader.setLoaderState({ loading: true, type: 'backdrop' });

    this.accountInfo.payAction({ type: 'all', commodityType: type }).subscribe();
  }

  addMeterReadingAction(): void {
    document.body.classList.add('no-scroll');
    this.loader.setLoaderState({ loading: true, type: 'backdrop' });

    this.googleAnalyticsService.eventEmitter(
      'Add meter reading',
      'in_app_page_view',
      'meter_reading_screen',
      'add_meter_reading_menu_button_clicked',
      1,
    );

    this.accountInfo.addMeterReading().subscribe();
  }

  logout(): void {
    // TODO flow-x should reset processes on logout
    this.googleAnalyticsService.eventEmitter('Log-out', 'custom_events', 'logout', 'click', 1);

    localStorage.removeItem('processInstances');
    this.oauthService.logOut();
  }

  goToNotifications(): void {
    if (this.activeMenuItem === 'notifications') {
      return;
    }

    this.renderer.addClass(document.body, 'child-view-loader');
    this.renderer.addClass(document.body, 'hide-loading-backdrop');

    this.activeMenuItem = 'notifications';
    this.appEventsService.setActiveMenuItem({ activeMenuItem: 'notifications' });

    this.router.navigate(['main', 'notifications']);
  }

  get activeNotifications(): boolean {
    return this.activeMenuItem === 'notifications';
  }
}
