<div *ngIf="tickets" class="tickets-container">
  <div *ngIf="tickets.length">
    <ppf-card class="pb-0">
      <h3 *ngIf="title" class="headline ml-24 mt-16">{{ title }}</h3>
    </ppf-card>
    <omc-ticket-details
      *ngFor="let ticket of tickets"
      [ticket]="ticket"
      [modalLabel]="modalLabel"
      [showButton]="true"
    ></omc-ticket-details>
  </div>
  <div *ngIf="tickets.length === 0" fxLayout="column" fxLayoutAlign="center center" class="mt-96">
    <img src="../assets/images/empty-state-tickets.svg" alt="empty state image" class="empty-state-image" />
    <p>{{ emptyStateLabel }}</p>
    <ppf-button class="open-ticket-button" [title]="buttonLabel" (onClick)="openModalAction()"></ppf-button>
  </div>
</div>
