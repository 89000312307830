import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppEventsService } from '@app/shared/services/app-events.service';
import { Observable } from 'rxjs';
import { SidebarUserInfo } from '@app/main/menu/dictionary';
import { InvoicesInput } from '@app/main/invoices/interfaces';
import { ConsumptionValuesInput } from '@app/main/consumption-points/interfaces';
import { Payment } from '@app/main/payments/interfaces';

@Component({
  selector: 'omc-commodity',
  templateUrl: './commodity.component.html',
  styleUrls: ['./commodity.scss'],
})
export class CommodityComponent implements OnInit, OnDestroy {
  @Input() data$: Observable<any>;
  public reducedData$: Observable<any>;
  public invoicesData: InvoicesInput;
  public consumptionPointsData: ConsumptionValuesInput;
  public paymentsData: Array<Payment>;
  public actionsFn: any;

  private appEvent$ = this.appEventsService.appEvent$;
  public accountInfo: SidebarUserInfo;

  public selectedIndex = 0;
  public key = 'collaborationGasInformation';
  public route = 'gas';
  public labels: any;

  constructor(private appEventsService: AppEventsService) {}

  ngOnInit(): void {
    this.reducedData$ = this.appEventsService.userEventsInit(this.data$, this.route, this.key);
    this.reducedData$.subscribe((data) => {
      this.labels = data.labels;
      this.invoicesData = data.invoiceSection;
      this.consumptionPointsData = data.consumptionSection;
      this.paymentsData = data.paymentSection;
      this.actionsFn = data.actionsFn;
    });

    this.appEvent$.subscribe((data) => {
      this.accountInfo = data.accountInfo;
    });
  }

  ngOnDestroy(): void {
    this.appEventsService.unsubscribeUserEvents();
  }
}
