<canvas
  baseChart
  [datasets]="data"
  [labels]="labels"
  [options]="options"
  [plugins]="barChartPlugins"
  [legend]="true"
  chartType="bar"
  height="230px"
>
</canvas>
